import { gql } from "@apollo/client";

export const CREATE_GROUP = gql`
    mutation createGroup($tripId: String!, $input: CreateGroupInput!) {
        createGroup(tripId: $tripId, input: $input) {
            success
            message
            group {
                id
                groupName
            }
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation updateGroup($groupId: String!, $input: UpdateGroupInput!) {
        updateGroup(groupId: $groupId, input: $input) {
            success
            message
            group {
                id
                groupName
            }
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation deleteGroup($groupId: String!) {
        deleteGroup(groupId: $groupId) {
            success
            message
        }
    }
`;

export const CREATE_REPORT = gql`
    mutation createReport($input: CreateReportInput!) {
        createReport(input: $input) {
            success
            message
        }
    }
`;

export const UPDATE_REPORT = gql`
    mutation updateReport($reportId: String!, $input: UpdateReportInput!) {
        updateReport(reportId: $reportId, input: $input) {
            success
            message
        }
    }
`;
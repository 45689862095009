import React, { useEffect, useState } from "react";
import style from "./ProfilePTMLeft.module.scss";
import { useQuery, QueryResult } from "@apollo/client";
import { useParams } from "react-router-dom";
import Modal from "src/components/Modal/Modal";
import ImageSlider from "src/components/IU/Slider/ImageSlider";
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import UserTags from "../Tags/UserTags";
import ListUserGroups from "../ListUserGroups/ListUserGroups";
import { GET_GROUP_BY_ADMIN } from "src/graphql/queries/ptm";
import {
	getPreferences,
	getPreferencesVariables,
} from "src/graphql/queries/__generated__/getPreferences";
import {
	getGroupByAdmin,
	getGroupByAdminVariables,
} from "src/graphql/queries/__generated__/getGroupByAdmin";

interface IProfilePTMProps {
	prefs: QueryResult<getPreferences, getPreferencesVariables>,
}

const ProfilePTMLeft: React.FunctionComponent<IProfilePTMProps> = (props) => {
	const params = useParams();
	const [showImageModale, setShowImageModale] = useState<boolean>(false);
	const [images, setImages] = useState<string[]>([]);
	const [showCreatedGroups, setShowCreatedGroups] = useState<boolean>(true);
	const preferences = props.prefs.data?.getUserPreferences;
	const userInterests = preferences?.userInterests;
	const userStyles = preferences?.userTravelStyle;
	const userPictures = preferences?.userPictures;
	const getGroups = useQuery<getGroupByAdmin, getGroupByAdminVariables>(
		GET_GROUP_BY_ADMIN,
		{ variables: { groupAdminId: params?.id! }, fetchPolicy: "network-only" }
	);
	const createdGroups = getGroups?.data?.getGroupByAdmin.createdGroups;
	const joinedGroups = getGroups?.data?.getGroupByAdmin.joinedGroups;

	const interestTags = userInterests ? Object.keys(userInterests).filter((key) => userInterests[key] === true).map((key) => {
		switch (key) {
			case 'sport':
				return 'Sport';
			case 'photoVideo':
				return 'Photo & Vidéo';
			case 'hiking':
				return 'Randonée';
			case 'lecture':
				return 'Lecture';
			case 'history':
				return 'Histoire';
			case 'mode':
				return 'Mode';
			case 'spaAndSwimming':
				return 'Spa/Piscine';
			case 'food':
				return 'Food';
			case 'dance':
				return 'Danse';
			case 'museum':
				return 'Musée';
			case 'seriesFilm':
				return 'Série&Film';
			case 'music':
				return 'Musique';
			default:
				return key.charAt(0).toUpperCase() + key.slice(1);
		}
	}) : [];

	const styleTags = userStyles ? Object.keys(userStyles).filter((key) => userStyles[key] === true).map((key) => {
		switch (key) {
			case 'backpack':
				return 'Backpack';
			case 'camping':
				return 'Camping';
			case 'hotel5':
				return 'Hôtel 5';
			case 'safari':
				return 'Safari';
			case 'greenSustainable':
				return 'Vert & Durable';
			case 'guidedExcursion':
				return 'Excursion Guidée';
			case 'excursionGroup':
				return 'Excursions Groupées';
			case 'cultural':
				return 'Culturel';
			case 'cruise':
				return 'Croisière';
			case 'landscapeNatural':
				return 'Paysage Naturel';
			case 'beachIdleness':
				return 'Plage & Far Niente';
			case 'roadTrip':
				return 'Roadtrip';
			default:
				return key.charAt(0).toUpperCase() + key.slice(1);
		}
	}) : [];

	useEffect(() => {
		const userImages = userPictures ? userPictures.map((picture) => picture.url) : [];
		setImages(userImages);
	}, [userPictures])

	return (
		<>
			{showImageModale && (
				<Modal
					setOpenModal={setShowImageModale}
					backgroundColor="0px 0px 0px 4000px rgba(13, 13, 13, 0.4) "
					boxShadow={"none"}
				>
					<div className={`swiper ${style.imageSlider}`}>
						<ImageSlider images={images} onClose={() => setShowImageModale(false)} />
					</div>
				</Modal>
			)}
			<Path />
			{props.prefs.data ?
				<>
					<div className={style["wrapper__container"]}>
						<div className={style["wrapper__container-tags"]}>
							{[
								{
									title: "Centre d'intérêts",
									tags: interestTags,
								},
								{
									title: "Style de voyage",
									tags: styleTags,
								},
							].map(({ title, tags }, index) => (
								<UserTags title={title} tags={tags} key={index} />
							))}
						</div>
						<div className={style["wrapper__container-images"]} onClick={() => (images.length > 0 ? setShowImageModale(true) : setShowImageModale(false))}>
							<div className={style["img__description_layer"]}></div>
							<img
								src={images[0] ?? "/img/default-profile-pic.png"}
								alt="gallery-icon"
								className={style["wrapper__container-images-image"]}
							/>
							<span className={style["wrapper__container-images-icon"]}>
								<img src="/svg/gallery-icon.svg" alt="gallery-icon" />
								<span className={style["wrapper__container-images-icon-text"]}> Photos ({`${images.length}`})</span>
							</span>
						</div>
					</div>
					<div className={style["wrapper-groups__container"]}>
						<div className={style["wrapper-groups__container-head"]}>
							<Button
								text={`Groupes crées (${createdGroups?.length})`}
								type={"button"}
								color={showCreatedGroups ? "#FDB242" : "#989898"}
								backgroundColor={showCreatedGroups ? "#2D2C6C" : "#EEEEEE"}
								width={190}
								height={45}
								borderColor={showCreatedGroups ? "#2D2C6C" : "#989898"}
								borderRadius={51}
								onClick={() => setShowCreatedGroups(true)}
							/>
							<Button
								text={`Groupes rejointes (${joinedGroups?.length})`}
								type={"button"}
								color={!showCreatedGroups ? "#FDB242" : "#989898"}
								backgroundColor={!showCreatedGroups ? "#2D2C6C" : "#EEEEEE"}
								width={190}
								height={45}
								borderColor={!showCreatedGroups ? "#2D2C6C" : "#989898"}
								borderRadius={51}
								onClick={() => setShowCreatedGroups(false)}
							/>
						</div>
						<ListUserGroups showCreatedGroups={showCreatedGroups} createdGroups={createdGroups} joinedGroups={joinedGroups} />
					</div>
				</>
				:
				<>
					<div className={style["wrapper-no-ptm"]}>L'utilisateur n'a pas encore crée son profil pour le Perfect Travel Match</div>
				</>
			}
		</>
	);
};

export default ProfilePTMLeft;

import style from "./Filter.module.scss";
import Frame from "../IU/Frame/Frame";
import { useQuery } from "@apollo/client";
import { GET_TRIP_CATEGORIES } from "../../graphql/queries/trip";
import { getTrackBackground, Range } from "react-range";
import { useEffect, useRef, useState } from "react";
import { CONTINENTS } from "../Deals/DealFormAffiliate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  IDepartureDateInput,
  setCategoryFilter,
  setContinentFilter,
  setDepartureCityFilter,
  setPriceMaxFilter,
  setPriceMinFilter,
  setStatusFilter,
} from "../../store/filter-trip-slice";
import MonthPicker from "../IU/DatePicker/MonthPicker/MonthPicker";

const DEPARTURE_CITY = [
  {
    id: "Paris",
    name: "Paris",
  },
  {
    id: "Lyon",
    name: "Lyon",
  },
];

const STATUS = [
  {
    id: "active",
    name: "Activé",
  },
  {
    id: "deactive",
    name: "Désactivé",
  },
];

interface IFilterItemProps {
  label: string;
  icon: string;
  type: string;
}
const FilterItem = ({ label, icon, type }: IFilterItemProps) => {
  const [showCategory, setShowCategory] = useState(false);
  const [showContinent, setShowContinent] = useState(false);
  const [showDeparture, setShowDeparture] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showPriceRange, setShowPriceRange] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const filterOptionContainer: any = useRef();
  const dispatch = useDispatch();
  const {
    priceMinFilter,
    priceMaxFilter,
    continentFilter,
    departureCityFilter,
    categoryFilter,
    departureDateFilter,
    statusFilter,
  } = useSelector((state: RootState) => state.filtersTrip);
  const { loading, error, data } = useQuery<any>(GET_TRIP_CATEGORIES, {
    variables: { page: 0, limit: 100 },
  });

  useEffect(() => {
    const onClickWindow = (e: any) => {
      if (filterOptionContainer?.current?.contains(e?.target as Node)) {
        return;
      }

      setShowCategory(false);
      setShowDeparture(false);
      setShowDate(false);
      setShowContinent(false);
      setShowPriceRange(false);
      setShowStatus(false);
    };

    window.addEventListener("click", onClickWindow);

    return () => window.removeEventListener("click", onClickWindow);
  }, []);

  const clickHandler = () => {
    switch (type) {
      case "continent":
        setShowContinent((prevState) => !prevState);
        break;
      case "category":
        setShowCategory((prevState) => !prevState);
        break;
      case "calendar":
        setShowDate((prevState) => !prevState);
        break;
      case "price":
        setShowPriceRange((prevState) => !prevState);
        break;
      case "departure":
        setShowDeparture((prevState) => !prevState);
        break;
      case "status":
        setShowStatus((prevState) => !prevState);
        break;
    }
  };

  const selectTripType = (e: any, cat: any) => {
    e.target.checked = !e.target.checked;

    if (e.target.checked) {
      dispatch(setCategoryFilter([...categoryFilter, cat.id]));
    } else {
      const newCat = categoryFilter.filter((c) => c !== cat.id);
      dispatch(setCategoryFilter(newCat));
    }
  };

  const selectContinent = (e: any, cont: any) => {
    e.target.checked = !e.target.checked;
    if (e.target.checked) {
      dispatch(setContinentFilter(cont));
    } else {
      dispatch(setContinentFilter(""));
    }
  };

  const selectDeparture = (e, cityID: string) => {
    e.target.checked = !e.target.checked;
    if (e.target.checked) {
      dispatch(setDepartureCityFilter(cityID));
    } else {
      dispatch(setDepartureCityFilter(""));
    }
  };

  const selectStatus = (e, stat: string) => {
    e.target.checked = !e.target.checked;
    if (e.target.checked) {
      dispatch(setStatusFilter(stat));
    } else {
      dispatch(setStatusFilter(""));
    }
  };

  return (
    <Frame borderRadius={"6px"} border={"0.7px solid #E0E3FF"}>
      <div className={style["filter"]} ref={filterOptionContainer}>
        <div className={style["filter-header"]} onClick={clickHandler}>
          <div className={style["text"]}>
            <img src={icon} alt={type + " icon"} />
            <span>{label}</span>
          </div>
          <img src={"/svg/add-blue.svg"} alt={"add icon"} />
        </div>

        <div className={style["filter-body"]}>
          {showDeparture &&
            DEPARTURE_CITY.map((city) => (
              <div
                key={city.id}
                onClick={(e) => selectDeparture(e, city.id)}
                className={style["option"]}
              >
                <input
                  type="checkbox"
                  id={city.name}
                  checked={departureCityFilter === city.id}
                  onChange={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => selectDeparture(e, city.id)}
                />
                <label htmlFor={city.name}>{city.name}</label>
              </div>
            ))}
          {showCategory &&
            data?.tripCategories?.map((category) => (
              <div
                key={category.id}
                onClick={(e) => selectTripType(e, category)}
                className={style["option"]}
              >
                <input
                  type="checkbox"
                  id={category.name}
                  checked={categoryFilter.includes(category.id)}
                  onChange={(e) => {}}
                  onClick={(e) => selectTripType(e, category)}
                />
                <label htmlFor={category.name}>{category.name}</label>
              </div>
            ))}
          {showContinent &&
            CONTINENTS.map((continent) => (
              <div
                key={continent.id}
                onClick={(e) => selectContinent(e, continent.id)}
                className={style["option"]}
              >
                <input
                  type="checkbox"
                  id={continent.id}
                  checked={continentFilter === continent.id}
                  onChange={(e) => {}}
                  onClick={(e) => selectContinent(e, continent.id)}
                />
                <label htmlFor={continent.name}>{continent.name}</label>
              </div>
            ))}
          {showDate && <MonthPicker />}
          {showPriceRange && (
            <div className={style["slider-container"]}>
              <div className={style["slider-labels"]}>
                <div
                  className={style["slider-labels__text"]}
                >{`${priceMinFilter}€`}</div>
                <div
                  className={style["slider-labels__text"]}
                >{`${priceMaxFilter}€`}</div>
              </div>
              <div className={style["slider"]}>
                <Range
                  min={0}
                  max={5000}
                  step={5}
                  values={[priceMinFilter, priceMaxFilter]}
                  renderThumb={({ props }) => {
                    return <div {...props} className={style["thumb"]}></div>;
                  }}
                  renderTrack={({ props, children }) => {
                    return (
                      <div
                        {...props}
                        style={{
                          position: "relative",
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: [priceMinFilter, priceMaxFilter],
                            colors: ["#E9E9E9", "#000", "#E9E9E9"],
                            min: 0,
                            max: 5000,
                          }),
                        }}
                      >
                        {children}
                      </div>
                    );
                  }}
                  onChange={(values) => {
                    dispatch(setPriceMinFilter(values[0]));
                    dispatch(setPriceMaxFilter(values[1]));
                  }}
                />
              </div>
            </div>
          )}
          {showStatus &&
            STATUS.map((stat) => (
              <div
                key={stat.id}
                onClick={(e) => selectStatus(e, stat.id)}
                className={style["option"]}
              >
                <input
                  type="checkbox"
                  id={stat.name}
                  checked={statusFilter == stat.id}
                  onChange={(e) => {}}
                  onClick={(e) => selectStatus(e, stat.id)}
                />
                <label htmlFor={stat.name}>{stat.name}</label>
              </div>
            ))}
        </div>
      </div>
    </Frame>
  );
};

export default FilterItem;

import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import style from "./PTMMessageDetails.module.scss";
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useQuery, useMutation } from "@apollo/client";
import { GET_REPORT } from "src/graphql/queries/ptm";
import { UPDATE_REPORT } from "src/graphql/mutations/ptm";
import {
    getReport,
	getReportVariables,
} from "src/graphql/queries/__generated__/getReport";
import {
    updateReport,
	updateReportVariables,
} from "src/graphql/mutations/__generated__/updateReport";

const PTMMessageDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const getReport = useQuery<getReport, getReportVariables>(
		GET_REPORT,
		{ variables: { reportId: params?.id! }, fetchPolicy: "network-only" }
	);
    const report = getReport?.data?.getReport;
    const [updateReport] = useMutation<updateReport, updateReportVariables>(UPDATE_REPORT);

    const MySwal = withReactContent(Swal);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const userDetail = (id: string) => {
		navigate(`/users/${id}`);
	};

    const formatFrenchEndDate = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        const formattedDate = new Intl.DateTimeFormat('fr-FR', {
            weekday: "short",   // Full weekday name (e.g., "jeu.")
            day: "numeric",     // Day of the month (e.g., "14")
            month: "short",     // Full month name (e.g., "nov.")
            year: "numeric"     // Full year (e.g., "2024")
        }).format(date);
		return formattedDate.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

    const messageType = (type) => {
        switch (type) {
			case 'harassment':
				return ` a été signalée pour comportement de harcèlement.`;
			case 'identity_fraud':
				return ` a été signalé pour fraude d'identité.`;
			case 'scams':
				return ` a été signalé pour arnaque.`;
			case 'inappropriate_content':
				return ` a été signalé pour avoir partagé du contenu inapproprié.`;
			case 'hate_speech':
				return ` a été signalé pour discours de haine.`;
			default:
				return '';
		}
    }

    const formHandler = async (e: any) => {
        e.preventDefault();
        const adminReply = e.target["response"].value;
        const formData = {
            adminReply: adminReply
        }

        await updateReport({
            variables: {
                reportId: params?.id!,
                input: formData
            },
        })
            .then(() => {
                Toast.fire({
                    icon: "success",
                    title: "A répondu avec succès",
                });
                navigate("/ptm/ptm-messages");
            })
            .catch((e) =>
                MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong: \n " + (e as Error).message,
                })
            );
    }

    return (
        <div>
            <Path />
            <div className={style["container"]}>
                <div className={style["container-upper"]}>
                    <div className={style["container-upper-user"]}>
                        <img src={report?.reportingUser?.media?.url ?? "/img/default-profile-pic.png"} alt="" />
                        <div>
                            <div className={style["container-upper-user-name"]}>{report?.reportingUser?.firstName} {report?.reportingUser?.lastName}</div>
                            <div className={style["container-upper-user-date"]}>{formatFrenchEndDate(report?.createdAt ?? 0)}</div>
                        </div>
                    </div>
                    <div className={style["container-upper-message"]}>
                        L'utilisateur{" "}
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                userDetail(report?.reportedUser?.id ?? "")
                            }}
                            className={style["reported-user"]}
                        >
                            {report?.reportedUser?.firstName} {report?.reportedUser?.lastName}
                            <span className={style["report-count"]}> ({report?.reportCount})</span>
                        </span>
                        {messageType(report?.type)}
                    </div>
                </div>
                <div className={style["container-lower"]}>
                    <form onSubmit={(e) => { formHandler(e) }}>
                        <textarea name="response" id="response" placeholder="Répondre..." />
                        <div className={style["container-lower-button"]}>
                            <Button
                                text={"Envoyer"}
                                fontSize={1}
                                fontWeight={"bold"}
                                type={"submit"}
                                color={"#FFFFFF"}
                                backgroundColor={"#353472"}
                                width={193.21}
                                height={55}
                                borderRadius={10}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PTMMessageDetails
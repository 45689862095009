import React, { useState } from "react";
import style from "./ProfileBNALeft.module.scss";
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import DatePicker from "../../IU/DatePicker/DatePicker";

const ProfileBNALeft = () => {
	const [startDate, setStartDate] = useState<Date>(new Date("10/01/2022"));
    const [endDate, setEndDate] = useState<Date>(new Date("10/19/2022"));
	const [showDate, setShowDate]: any = useState(false);

	const showDateHandler = () => {
        setShowDate(!showDate)
    }

	let displayDate = "";
	if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {day: "numeric"})} - ${endDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: 'short',
            year: 'numeric'
        })}`
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: "short"
        })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
    } else {
        displayDate = `${startDate.toLocaleDateString('fr-FR', {
            day: "numeric",
            month: 'short',
            year: 'numeric'
        })} - ${endDate.toLocaleDateString('fr-FR', {day: "numeric", month: 'short', year: 'numeric'})}`
    }

    return (
        <>
            <div className={style['chart-header-date']}>
				<Path />
				<Button
					text={displayDate}
					width={115}
					height={23}
					fontSize={0.75}
					color={"#1562D6"}
					borderRadius={5.18}
					backgroundColor={"#E0E6FB"}
					borderColor={"#1562D6"}
					fontWeight={"600"}
					onClick={showDateHandler}
					suffixIcon={{
						url: "/svg/arrow-down.svg",
						alt: "arrow down",
						style: {
							marginLeft: "5px",
							paddingRight: "5px"
						}
					}}
				/>

				{showDate && <DatePicker
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
				/>}
			</div>
			<div className={style["wrapper__container-card"]}>
				<div className={style["wrapper__container-card-activities"]}>
					<div className={style["wrapper__container-card-activities-title"]}>Activités sur le site</div>
					<div className={style["wrapper__container-card-activities-text"]}>Nombre de visites</div>
					<div className={style["wrapper__container-card-activities-value"]}>
						526
						<div>
							<img src="/img/graph-up-icon.svg" alt="graph-up" />{" "}3,4%
						</div>
					</div>
				</div>
				<div className={style["wrapper__container-card-newsletter"]}>
					<div className={style["wrapper__container-card-newsletter-title"]}>Newsletter</div>
					<div className={style["wrapper__container-card-newsletter-text"]}>Taux d'ouverture</div>
					<div className={style["wrapper__container-card-newsletter-value"]}>
						80%
						<div>
							<img src="/img/graph-down-icon.svg" alt="graph-down" />{" "}1,4%
						</div>
					</div>
				</div>
				<div className={style["wrapper__container-card-sponsorship"]}>
					<div className={style["wrapper__container-card-sponsorship-title"]}>Parrainage</div>
					<div className={style["wrapper__container-card-sponsorship-text"]}>Nombre de parrains</div>
					<div className={style["wrapper__container-card-sponsorship-value"]}>1</div>
				</div>
				<div className={style["wrapper__container-card-basket"]}>
					<div className={style["wrapper__container-card-basket-title"]}>Panier moyen</div>
					<div className={style["wrapper__container-card-basket-text"]}>Dépense moyenne</div>
					<div className={style["wrapper__container-card-basket-value"]}>
						100,2 €
						<div>
							<img src="/img/graph-up-icon.svg" alt="graph-up" />{" "}3,4%
						</div>
					</div>
				</div>
			</div>
			<div className={style["wrapper__container-tab"]}>
				<div className={style["wrapper__container-tab-visits"]}>
					<div><img src="/img/navigate-icon.svg" alt="navigate" /></div>
					<div>
						<div className={style["wrapper__container-tab-visits-title"]}>Nombre de visites</div>
						<div className={style["wrapper__container-tab-visits-value"]}>526</div>
					</div>
				</div>
				<div className={style["wrapper__container-tab-site-rate"]}>
					<div><img src="/img/web-search-icon.svg" alt="web-search" /></div>
					<div>
						<div className={style["wrapper__container-tab-site-rate-title"]}>Taux moyen du site</div>
						<div className={style["wrapper__container-tab-site-rate-value"]}>5%</div>
					</div>
				</div>
				<div className={style["wrapper__container-tab-cta-rate"]}>
					<div><img src="/img/cursor-click-icon.svg" alt="cursor-click" /></div>
					<div>
						<div className={style["wrapper__container-tab-cta-rate-title"]}>Taux de CTA</div>
						<div className={style["wrapper__container-tab-cta-rate-value"]}>10%</div>
					</div>
				</div>
				<div className={style["wrapper__container-tab-reservations"]}>
					<div><img src="/img/ticket-icon.svg" alt="ticket" /></div>
					<div>
						<div className={style["wrapper__container-tab-reservations-title"]}>Nb de réservations</div>
						<div className={style["wrapper__container-tab-reservations-value"]}>2</div>
					</div>
				</div>
			</div>
			<div className={style["wrapper__container-table"]}>
				<div className={style["wrapper__container-table-title"]}>Top Destinations</div>
				<div className={style["wrapper__container-table-title-border"]}></div>
				<div className={style["table-scroll"]}>
					<table>
						<thead>
							<tr>
								<th>Détail de bon plan</th>
								<th>Prix</th>
								<th>Taux de clique</th>
								<th>Nombre de réservation</th>
								<th>Type de bon plan</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Santorini.png" alt="Santorini" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Santorini, Grèce</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Grèce</div>
									</div>
								</td>
								<td>102€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>22%</span>
									</div>
								</td>
								<td>1</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Berlin.png" alt="Berlin" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Berlin, Allemand</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Allemand</div>
									</div>
								</td>
								<td>35€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>20%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Tokyo.png" alt="Tokyo" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Tokyo, Japon</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Japon</div>
									</div>
								</td>
								<td>450€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>20%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol+Hôtel</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Seoul.png" alt="Seoul" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Seoul, Corée</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Corée</div>
									</div>
								</td>
								<td>502€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>18%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Venice.png" alt="Venice" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Venice, Italie</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Italie</div>
									</div>
								</td>
								<td>198,2€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>17%</span>
									</div>
								</td>
								<td>1</td>
								<td>Vol+Hôtel</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Berlin.png" alt="Berlin" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Berlin, Allemand</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Allemand</div>
									</div>
								</td>
								<td>35€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>10%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Seoul.png" alt="Seoul" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Seoul, Corée</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Corée</div>
									</div>
								</td>
								<td>502€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>18%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Venice.png" alt="Venice" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Venice, Italie</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Italie</div>
									</div>
								</td>
								<td>198,2€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>17%</span>
									</div>
								</td>
								<td>1</td>
								<td>Vol+Hôtel</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Berlin.png" alt="Berlin" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Berlin, Allemand</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Allemand</div>
									</div>
								</td>
								<td>35€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>10%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Seoul.png" alt="Seoul" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Seoul, Corée</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Corée</div>
									</div>
								</td>
								<td>502€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>18%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Venice.png" alt="Venice" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Venice, Italie</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Italie</div>
									</div>
								</td>
								<td>198,2€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>17%</span>
									</div>
								</td>
								<td>1</td>
								<td>Vol+Hôtel</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Berlin.png" alt="Berlin" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Berlin, Allemand</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Allemand</div>
									</div>
								</td>
								<td>35€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>10%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Seoul.png" alt="Seoul" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Seoul, Corée</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Corée</div>
									</div>
								</td>
								<td>502€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>18%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Venice.png" alt="Venice" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Venice, Italie</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Italie</div>
									</div>
								</td>
								<td>198,2€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>17%</span>
									</div>
								</td>
								<td>1</td>
								<td>Vol+Hôtel</td>
							</tr>
							<tr>
								<td className={style["wrapper__container-table-plan"]}>
									<div><img src="/img/Berlin.png" alt="Berlin" /></div>
									<div>
										<div className={style["wrapper__container-table-plan-to"]}>Berlin, Allemand</div>
										<div className={style["wrapper__container-table-plan-from"]}>De Paris à Allemand</div>
									</div>
								</td>
								<td>35€</td>
								<td>
									<div className={style["wrapper__container-table-rate"]}>
										<img src="/img/arrow-up-icon.svg" alt="arrow-up" />
										<span>10%</span>
									</div>
								</td>
								<td>0</td>
								<td>Vol A/R</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
        </>
    )
}

export default ProfileBNALeft;

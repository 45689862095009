import { useEffect, useState } from 'react';
import style from "./PTMGroupForm.module.scss";
import Path from "../../Path/Path";
import RadioButton from "../../IU/RadioButton/RadioButton";
import Select from "../../IU/Select/Select";
import Button from "../../IU/Button/Button";
import InputError from "../../IU/InputError/InputError";
import TripsModal from "./TripsModal/TripsModal";
import UserTags from "../../User/Tags/UserTags";
import { Range, getTrackBackground } from "react-range";
import { useNavigate, useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useQuery, useMutation } from "@apollo/client";
import { getPreferences, getPreferencesVariables } from "../../../graphql/queries/__generated__/getPreferences";
import { getGroup, getGroupVariables } from "../../../graphql/queries/__generated__/getGroup";
import { createGroup, createGroupVariables } from "../../../graphql/mutations/__generated__/createGroup";
import { updateGroup, updateGroupVariables } from "../../../graphql/mutations/__generated__/updateGroup";
import { GET_USER_PREFERENCES } from "../../../graphql/queries/ptm";
import { GET_GROUP } from "../../../graphql/queries/ptm";
import { CREATE_GROUP, UPDATE_GROUP } from "../../../graphql/mutations/ptm";

export const GENDER = [
    { id: "mixed", name: "Mixte" },
    { id: "man", name: "Homme uniquement" },
    { id: "woman", name: "Femme uniquement" },
];

export const CRITERIA = [
    { id: "sport", name: "Sport" },
    { id: "photoVideo", name: "Photo/Vidéo" },
    { id: "hiking", name: "Randonné" },
    { id: "lecture", name: "Lecture" },
    { id: "history", name: "Histoire" },
    { id: "mode", name: "Mode" },
    { id: "spaAndSwimming", name: "Spa/Piscine" },
    { id: "food", name: "Food" },
    { id: "dance", name: "Danse" },
    { id: "museum", name: "Musée" },
    { id: "seriesFilm", name: "Séries & Films" },
    { id: "music", name: "Musique" },
    { id: "backpack", name: "Backpack" },
    { id: "camping", name: "Camping" },
    { id: "hotel5", name: "Hôtel 5*" },
    { id: "safari", name: "Safari" },
    { id: "greenSustainable", name: "Vert & durable" },
    { id: "guidedExcursion", name: "Excursion guidée" },
    { id: "excursionGroup", name: "Excursion groupé" },
    { id: "cultural", name: "Culture" },
    { id: "cruise", name: "Croisière" },
    { id: "landscapeNatural", name: "Paysage naturel" },
    { id: "beachIdleness", name: "Plage & Far niente" },
    { id: "roadTrip", name: "Roadtrip" },
];

type errors = {
    [key: string]: boolean;
};

const PTMGroupForm = () => {
    const navigate = useNavigate();
    const params = useParams();
    const context = JSON.parse(localStorage.getItem("bna-user-context") ?? "")
    const [rangeBudget, setRangeBudget] = useState([0, 2000]);
    const [participants, setParticipants] = useState<string>('');
    const [errors, setErrors] = useState<errors>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState<any>(null);
    const [selectCriteria, setSelectCriteria] = useState<any>([]);

    const preferences = useQuery<getPreferences, getPreferencesVariables>(
        GET_USER_PREFERENCES,
        { variables: { userId: context.id }, fetchPolicy: "network-only" }
    );
    const userPrefs = preferences?.data?.getUserPreferences;
    const getGroup = useQuery<getGroup, getGroupVariables>(
        GET_GROUP,
        { variables: { groupId: params?.id! }, fetchPolicy: "network-only" }
    );
    const group = getGroup?.data?.getGroup;
    const [createGroup] = useMutation<createGroup, createGroupVariables>(CREATE_GROUP);
    const [updateGroup] = useMutation<updateGroup, updateGroupVariables>(UPDATE_GROUP);

    const MySwal = withReactContent(Swal);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const increment = () => setParticipants(prev => (prev === '' ? '2' : (parseInt(prev) + 1).toString()));
    const decrement = () => setParticipants(prev => (prev === '' || parseInt(prev) <= 2 ? '' : (parseInt(prev) - 1).toString()));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        // Allow only numbers and empty string, and limit to 2 – 5
        if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 2 && parseInt(value) <= 5)) {
            setParticipants(value);
        }
    };

    const handleSelectTrip = (trip: any) => {
        setSelectedTrip(trip);
    };

    const formatFrenchStartDate = (timestamp: number): string => {
        return new Date(timestamp * 1000)
            .toLocaleDateString('fr-FR', { day: "numeric", month: "long" })
            .replace(/\b(\w)/g, (match) => match.toUpperCase());
    }

    const formatFrenchEndDate = (timestamp: number): string => {
        return new Date(timestamp * 1000)
            .toLocaleDateString('fr-FR', { day: "numeric", month: "long", year: "numeric" })
            .replace(/\b(\w)/g, (match) => match.toUpperCase());
    }

    const tripType = (type: string) => {
        switch (type) {
            case 'all':
                return 'Vol+Hôtel';
            case 'flight':
                return 'Vol A/R';
            case 'hotel':
                return 'Hôtel';
            case 'bus':
                return 'Bus A/R';
            case 'train':
                return 'Train A/R';
            default:
                return;
        }
    }

    const groupGender = (gender: any) => {
        if (gender.man === true) {
            return "man";
        } else if (gender.woman === true) {
            return "woman";
        } else if (gender.mixed === true) {
            return "mixed";
        }
    }

    const groupCriteria = selectCriteria?.map(criteria => criteria.name)

    useEffect(() => {
        if (group) {
            setParticipants(group?.membersLimit?.toString() ?? "")
            setRangeBudget([group.groupBudget.minimumAmount, group.groupBudget.maximumAmount])
            handleSelectTrip(group.trip)
        }
    }, [group])

    const getFormData = (e): any => {
        const budget = {
            minimumAmount: rangeBudget[0],
            maximumAmount: rangeBudget[1]
        }

        const groupGender = {
            man: e.target["gender"].value === "man" ? true : false,
            woman: e.target["gender"].value === "woman" ? true : false,
            mixed: e.target["gender"].value === "mixed" ? true : false,
        }

        const groupPreferences = {
            groupTravelWith: groupGender,
            groupAgeRange: group ? group?.preferences?.groupAgeRange : userPrefs?.userAgeRange,
            groupPersonality: group ? group?.preferences?.groupPersonality : userPrefs?.userPersonality,
            groupSociability: group ? group?.preferences?.groupSociability : userPrefs?.userSociability,
            groupInterests: group ? group?.preferences?.groupInterests : userPrefs?.userInterests,
            groupMood: group ? group?.preferences?.groupMood : userPrefs?.userMood,
            groupDrinking: group ? group?.preferences?.groupDrinking : userPrefs?.userDrinking,
            groupSmoking: group ? group?.preferences?.groupSmoking : userPrefs?.userSmoking,
            groupDiet: group ? group?.preferences?.groupDiet : userPrefs?.userDiet,
            groupSpending: group ? group?.preferences?.groupSpending : userPrefs?.userSpending,
            groupCleaning: group ? group?.preferences?.groupCleaning : userPrefs?.userCleaning,
            groupTransport: group ? group?.preferences?.groupTransport : userPrefs?.userTransport,
            groupTravelStyle: group ? group?.preferences?.groupTravelStyle : userPrefs?.userTravelStyle,
            groupTravelLevel: group ? group?.preferences?.groupTravelLevel : userPrefs?.userTravelLevel
        }

        const formData: any = {
            groupName: e.target["groupName"].value,
            description: e.target["description"].value,
            preferences: groupPreferences,
            membersLimit: e.target["membersLimit"].value,
            groupBudget: budget,
            criteria: groupCriteria?.concat(group ? group?.criteria?.filter(value => !groupCriteria.includes(value)) : [])
        };

        const updateTripID: any = {
            tripId: selectedTrip?.id
        };

        if (group) {
            Object.assign(formData, updateTripID)
        }

        const emptyFields: errors = {};

        Object.keys(formData).forEach((field: string) => {
            if (field === "groupName" || field === "membersLimit" || field === "description") {
                if (formData[field] === "") {
                    emptyFields[field] = true;
                }
            }
            if (field === "preferences") {
                if (!Object.values(formData[field].groupTravelWith).some(item => item === true)) {
                    emptyFields["gender"] = true
                }
            }
        });

        if (Object.keys(emptyFields).length > 0) {
            setErrors({ ...emptyFields });
            return null;
        }
        setErrors({});

        return formData;
    };

    const formHandler = async (e: any) => {
        e.preventDefault();
        const formData = getFormData(e);
        if (Object.keys(errors).length !== 0) {
            return;
        }

        if (!group) {
            await createGroup({
                variables: {
                    tripId: selectedTrip?.id,
                    input: formData
                },
            })
                .then(() => {
                    Toast.fire({
                        icon: "success",
                        title: "Groupe créé",
                    });
                    navigate("/ptm/ptm-groups");
                })
                .catch((e) =>
                    MySwal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong: \n " + (e as Error).message,
                    })
                );
        } else {
            try {
                await updateGroup({
                    variables: {
                        groupId: params?.id!,
                        input: formData,
                    },
                });

                await Toast.fire({
                    icon: "success",
                    title: "Groupe à jour",
                });
                navigate("/ptm/ptm-groups");
            } catch (e) {
                console.error(e);

                await MySwal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong: \n " + (e as Error).message,
                });
            }
        }
    };

    return (
        <>
            <Path />
            <div>
                <form onSubmit={(e) => { formHandler(e) }}>
                    <div className={style['form-wrapper']}>
                        <div className={style['form-title']}>Informations groupes</div>
                        <div className={style['form-multiple']}>
                            <div className={style['form-group']}>
                                <label htmlFor={"groupName"}>Nom du groupe</label>
                                <input id={"groupName"} name="groupName" type="text" className={style['form-input-groupName']} defaultValue={group?.groupName ?? ""} />
                                {errors.hasOwnProperty("groupName") && (
                                    <InputError message="Saisis un name" />
                                )}
                            </div>
                            <div className={style['form-group']}>
                                <label htmlFor={"membersLimit"}>Nombre de participants</label>
                                <div className={style['form-group-participants']}>
                                    <input
                                        id="membersLimit"
                                        name='membersLimit'
                                        type="text"
                                        value={participants}
                                        onChange={handleChange}
                                        className={style['form-input-participants']}
                                    />
                                    <div className={style['form-input-participants-arrow']}>
                                        <button
                                            type='button'
                                            onClick={increment}
                                            disabled={parseInt(participants) === 5}
                                            className={style['form-input-participants-arrow-up']}
                                        >
                                            {parseInt(participants) === 5 ?
                                                <img src="/svg/input-arrow-down-disabled.svg" alt="" className={style["arrow-rotate"]} />
                                                :
                                                <img src="/svg/input-arrow-up.svg" alt="" />
                                            }
                                        </button>
                                        <button
                                            type='button'
                                            onClick={decrement}
                                            disabled={participants === '' || parseInt(participants) === 0}
                                            className={style['form-input-participants-arrow-down']}
                                        >
                                            {participants === '' || parseInt(participants) === 0 ?
                                                <img src="/svg/input-arrow-down-disabled.svg" alt="" />
                                                :
                                                <img src="/svg/input-arrow-up.svg" alt="" className={style["arrow-rotate"]} />
                                            }
                                        </button>
                                    </div>
                                </div>
                                {errors.hasOwnProperty("membersLimit") && (
                                    <InputError message="Entrez le nombre de participants" />
                                )}
                            </div>
                        </div>
                        <div className={style['form-multiple']}>
                            <div className={style['form-group']}>
                                <label htmlFor={"creator"}>Nom du Créateur</label>
                                <input id={"creator"} name="creator" type="text" className={style['form-input-creatorName']} defaultValue={group?.adminInfo.adminName ?? ""} />
                            </div>
                            <div className={style["slider-container"]}>
                                <label>Budget</label>
                                <div className={style["slider"]}>
                                    <Range
                                        min={0}
                                        max={2000}
                                        step={5}
                                        values={rangeBudget!}
                                        renderThumb={({ props }) => {
                                            return (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        height: '24px',
                                                        width: '24px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#fff',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        boxShadow: '0px 2px 6px #AAA'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: '14px',
                                                            width: '14px',
                                                            borderRadius: '10px',
                                                            background: "#FFBD59",
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }}
                                        renderTrack={({ props, children }) => {
                                            return (
                                                <div
                                                    {...props}
                                                    style={{
                                                        position: "relative",
                                                        height: "5px",
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                        background: getTrackBackground({
                                                            values: rangeBudget!,
                                                            colors: ["#E9E9E9", "#FFBD59", "#E9E9E9"],
                                                            min: 0,
                                                            max: 2000,
                                                        }),
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            );
                                        }}
                                        onChange={(values) => {
                                            setRangeBudget(values);
                                        }}
                                    />
                                </div>
                                <div className={style["slider-labels"]}>
                                    <div className={style["slider-labels__text"]}>
                                        {rangeBudget[0]}€
                                    </div>
                                    <div className={style["slider-labels__text"]}>
                                        {rangeBudget[1]}€
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={style['form-group']}>
                            <label>Genres souhaités</label>
                            <RadioButton name={"gender"} data={GENDER} isPTM={true} defaultValue={group ? groupGender(group?.preferences?.groupTravelWith) : ""} />
                            {errors.hasOwnProperty("gender") && (
                                <InputError message="Entrez le sexe" />
                            )}
                        </div>
                        <div className={style['form-multiple']}>
                            <div className={style['form-multi']}>
                                <div className={style['form-title']}>Description du groupe</div>
                                <div className={style['form-group']}>
                                    <textarea name="description" id="description" cols={20} rows={10} placeholder="Écrire quelque chose..." defaultValue={group?.description ?? ""} />
                                    {errors.hasOwnProperty("description") && (
                                        <InputError message="Entrez la description" />
                                    )}
                                </div>
                            </div>
                            <div className={style['form-multi']}>
                                <div className={style['form-title']}>Critères du groupe</div>
                                <div className={style['form-group']}>
                                    <Select
                                        data={CRITERIA}
                                        value={selectCriteria}
                                        onChange={(n) => setSelectCriteria(n)}
                                        isMultiple={true}
                                    />
                                </div>
                                {group ?
                                    <UserTags title={""} tags={group.criteria ?? []} borderRadius={16} />
                                    :
                                    ""
                                }
                            </div>
                        </div>
                        <div className={style['form-group']}>
                            <div className={style['form-title']}>Destination du groupe</div>
                            {selectedTrip === null ?
                                <>
                                    <div className={style['form-group-destination']}>
                                        <div className={style['form-group-destination-text']}>Sélectionner un bon plan</div>
                                        <div className={style['form-group-destination-button']}>
                                            <Button
                                                text={"Voir les bons plans"}
                                                fontWeight={"bold"}
                                                type={"button"}
                                                color={"#2D2C6C"}
                                                backgroundColor={"#FDB242"}
                                                width={139}
                                                height={35}
                                                borderRadius={26}
                                                onClick={() => setIsModalOpen(true)}
                                            />
                                        </div>
                                    </div>
                                    <TripsModal
                                        isOpen={isModalOpen}
                                        onClose={() => setIsModalOpen(false)}
                                        onSelectTrip={handleSelectTrip}
                                    />
                                </>
                                :
                                <>
                                    <div className={style["trip"]}>
                                        <div className={style["trip-card"]}>
                                            <img src={selectedTrip.medias[0]?.url ?? "/img/default-card-bg.jpg"} alt={selectedTrip.city?.name} className={style["trip-image"]} />
                                            <div className={style["trip-info"]}>
                                                <h3>{selectedTrip.city?.name}, {selectedTrip.country?.name}</h3>
                                                <p>{tripType(selectedTrip?.type ?? "")}</p>
                                                <p>{formatFrenchStartDate(selectedTrip?.startDate ?? 0)} - {formatFrenchEndDate(selectedTrip?.endDate ?? 0)}</p>
                                                <p className={style["trip-price"]}>{selectedTrip.price}€</p>
                                            </div>
                                        </div>
                                        <Button
                                            text={"Changer"}
                                            fontWeight={"bold"}
                                            type={"button"}
                                            color={"#FFFFFF"}
                                            backgroundColor={"#353472"}
                                            width={172.55}
                                            height={55}
                                            borderRadius={10}
                                            onClick={() => setIsModalOpen(true)}
                                        />
                                        <TripsModal
                                            isOpen={isModalOpen}
                                            onClose={() => setIsModalOpen(false)}
                                            onSelectTrip={handleSelectTrip}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className={style['form-button']}>
                        <Button
                            text={!group ? "CRÉER" : "ENREGISTRER"}
                            type={"submit"}
                            color={"#FFF"}
                            backgroundColor={"#2D2C6C"}
                            width={140}
                            height={50}
                            borderRadius={10}
                            fontSize={1}
                            fontWeight={"bold"}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default PTMGroupForm

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Modal from 'react-modal';
import { TripsAdmin, TripsAdminVariables } from "../../../../graphql/queries/__generated__/TripsAdmin";
import { SEARCH_TRIP_ADMIN } from "../../../../graphql/queries/trip";
import style from './TripsModal.module.scss';

const TripsModal = ({ isOpen, onClose, onSelectTrip }) => {
    const appElement = document.getElementById('root') || undefined;
    const { loading, error, data } = useQuery<TripsAdmin, TripsAdminVariables>(
        SEARCH_TRIP_ADMIN,
		{ variables: { page: 0, limit: 50, search: {priceMax: 5000, status: "active"}, sort: {} }, fetchPolicy: "network-only" }
    );

    if (loading) return <p>Loading trips...</p>;
    if (error) return <p>Error fetching trips</p>;

    const formatFrenchStartDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

	const formatFrenchEndDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long", year: "numeric" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

    const tripType = (type: string) => {
		switch (type) {
			case 'all':
				return 'Vol+Hôtel';
			case 'flight':
				return 'Vol A/R';
			case 'hotel':
				return 'Hôtel';
			case 'bus':
				return 'Bus A/R';
			case 'train':
				return 'Train A/R';
			default:
				return;
		}
	}

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className={style["modal"]} appElement={appElement}>
            <div className={style["modal-header"]}>
                <h2>Sélectionnez un voyage</h2>
                <button onClick={onClose} className={style["modal-header-close-button"]}>&times;</button>
            </div>
            <div className={style["trip-list"]}>
                {data?.tripsAdmin.trips.map((trip) => (
                    <div 
                        key={trip.id} 
                        className={style["trip-card"]}
                        onClick={() => {
                            onSelectTrip(trip);
                            onClose();
                        }}
                    >
                        <img src={trip.medias[0]?.url ?? "/img/default-card-bg.jpg"} alt={trip.city?.name} className={style["trip-image"]} />
                        <div className={style["trip-info"]}>
                            <h3>{trip.city?.name}, {trip.country?.name}</h3>
                            <p>{tripType(trip?.type ?? "")}</p>
                            <p>{formatFrenchStartDate(trip?.startDate ?? 0)} - {formatFrenchEndDate(trip?.endDate ?? 0)}</p>
                            <p className={style["trip-price"]}>{trip.price}€</p>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default TripsModal;
import {gql} from "@apollo/client";

export const GET_USER_PREFERENCES = gql`
	query getPreferences($userId: String!) {
		getUserPreferences(userId: $userId) {
			userGender {
				male
				female
			}
			userTravelWith {
				man
				woman
				mixed
			}
			userAge
			userLanguage
			userAgeRange {
				age18To23
				age24To29
				age30To35
				ageOver35
			}
			userPersonality {
				extrovert
				introvert
				ambivert
			}
			userSociability {
				sociable
    			calm
			}
			userInterests {
				sport
				photoVideo
				hiking
				lecture
				history
				mode
				spaAndSwimming
				food
				dance
				museum
				seriesFilm
				music
			}
			userMood {
				party
				holySpirit
				organize
				freestyle
				morning
				night
				immortalize
				liveInMoment
			}
			userDrinking {
				regularly
				occasionally
				noDrink
			}
			userSmoking {
				everyday
				noSmoke
				occasionally
			}
			userDiet {
				vegan
				hallal
				casher
				vegetarian
				prescetarian
				eatAll
			}
			userSpending {
				asLittleAsPossible
				benefitWithCount
				asLongAsCardPasses
			}
			userCleaning {
				chill
				essential
				relaxation
			}
			userTransport {
				location
				foot
				publicTransport
				bike
				taxiVtc
				hitchHiking
			}
			userTravelStyle {
				backpack
				camping
				hotel5
				safari
				greenSustainable
				guidedExcursion
				excursionGroup
				cultural
				cruise
				landscapeNatural
				beachIdleness
				roadTrip
			}
			userTravelLevel {
				globeTrotter
				babyTraveller
				adventurer
			}
			userAvatar {
				url
    			name
			}
			userTravelStory
			userPictures {
				id
				directory
				url
				mimeType
				type
				size
				name
			}
			tutorialWatched
		}
	}
`;

export const LIST_GROUPS = gql`
	query listGroups($page: Int!, $limit: Int!, $isDashboard: Boolean) {
		listGroups(page: $page, limit: $limit, isDashboard: $isDashboard) {
			groups {
				id
				groupName
				trip {
					id
					city {
						id
						name
						countryName
					}
					startDate
					endDate
					price
					currency
					type
					medias {
						id
						url
						name
					}
				}
				description
				membersLimit
				groupAuthor
				adminInfo {
					adminID
					adminName
					adminUsername
				}
				preferences {
					groupTravelWith {
						man
						woman
						mixed
					}
					groupAgeRange {
						age18To23
						age24To29
						age30To35
						ageOver35
					}
					groupPersonality {
						extrovert
						introvert
						ambivert
					}
					groupSociability {
						sociable
						calm
					}
					groupInterests {
						sport
						photoVideo
						hiking
						lecture
						history
						mode
						spaAndSwimming
						food
						dance
						museum
						seriesFilm
						music
					}
					groupMood {
						party
						holySpirit
						organize
						freestyle
						morning
						night
						immortalize
						liveInMoment
					}
					groupDrinking {
						regularly
						occasionally
						noDrink
					}
					groupSmoking {
						everyday
						noSmoke
						occasionally
					}
					groupDiet {
						vegan
						hallal
						casher
						vegetarian
						prescetarian
						eatAll
					}
					groupSpending {
						asLittleAsPossible
						benefitWithCount
						asLongAsCardPasses
					}
					groupCleaning {
						chill
						essential
						relaxation
					}
					groupTransport {
						location
						foot
						publicTransport
						bike
						taxiVtc
						hitchHiking
					}
					groupTravelStyle {
						backpack
						camping
						hotel5
						safari
						greenSustainable
						guidedExcursion
						excursionGroup
						cultural
						cruise
						landscapeNatural
						beachIdleness
						roadTrip
					}
					groupTravelLevel {
						globeTrotter
						babyTraveller
						adventurer
					}
				}
				members {
					id
				}
				groupBudget {
					minimumAmount
					maximumAmount
				}
				criteria
				status
				createdAt
				deletedAt
			}
			totalCount
			validCount
			pastCount
		}
	}
`;

export const GET_GROUP_BY_ADMIN = gql`
	query getGroupByAdmin($groupAdminId: String!) {
		getGroupByAdmin(groupAdminId: $groupAdminId) {
			createdGroups {
				id
				groupName
				trip {
					id
					city {
						id
						name
						countryName
					}
					startDate
					endDate
					price
					currency
					type
					medias {
						id
						url
						name
					}
				}
				description
				membersLimit
				groupAuthor
				adminInfo {
					adminID
					adminName
					adminUsername
				}
				preferences {
					groupTravelWith {
						man
						woman
						mixed
					}
					groupAgeRange {
						age18To23
						age24To29
						age30To35
						ageOver35
					}
					groupPersonality {
						extrovert
						introvert
						ambivert
					}
					groupSociability {
						sociable
						calm
					}
					groupInterests {
						sport
						photoVideo
						hiking
						lecture
						history
						mode
						spaAndSwimming
						food
						dance
						museum
						seriesFilm
						music
					}
					groupMood {
						party
						holySpirit
						organize
						freestyle
						morning
						night
						immortalize
						liveInMoment
					}
					groupDrinking {
						regularly
						occasionally
						noDrink
					}
					groupSmoking {
						everyday
						noSmoke
						occasionally
					}
					groupDiet {
						vegan
						hallal
						casher
						vegetarian
						prescetarian
						eatAll
					}
					groupSpending {
						asLittleAsPossible
						benefitWithCount
						asLongAsCardPasses
					}
					groupCleaning {
						chill
						essential
						relaxation
					}
					groupTransport {
						location
						foot
						publicTransport
						bike
						taxiVtc
						hitchHiking
					}
					groupTravelStyle {
						backpack
						camping
						hotel5
						safari
						greenSustainable
						guidedExcursion
						excursionGroup
						cultural
						cruise
						landscapeNatural
						beachIdleness
						roadTrip
					}
					groupTravelLevel {
						globeTrotter
						babyTraveller
						adventurer
					}
				}
				members {
					id
				}
				groupBudget {
					minimumAmount
					maximumAmount
				}
				criteria
				status
				createdAt
			}
			joinedGroups {
				id
				groupName
				trip {
					id
					city {
						id
						name
						countryName
					}
					startDate
					endDate
					price
					currency
					type
					medias {
						id
						url
						name
					}
				}
				description
				membersLimit
				groupAuthor
				adminInfo {
					adminID
					adminName
					adminUsername
				}
				preferences {
					groupTravelWith {
						man
						woman
						mixed
					}
					groupAgeRange {
						age18To23
						age24To29
						age30To35
						ageOver35
					}
					groupPersonality {
						extrovert
						introvert
						ambivert
					}
					groupSociability {
						sociable
						calm
					}
					groupInterests {
						sport
						photoVideo
						hiking
						lecture
						history
						mode
						spaAndSwimming
						food
						dance
						museum
						seriesFilm
						music
					}
					groupMood {
						party
						holySpirit
						organize
						freestyle
						morning
						night
						immortalize
						liveInMoment
					}
					groupDrinking {
						regularly
						occasionally
						noDrink
					}
					groupSmoking {
						everyday
						noSmoke
						occasionally
					}
					groupDiet {
						vegan
						hallal
						casher
						vegetarian
						prescetarian
						eatAll
					}
					groupSpending {
						asLittleAsPossible
						benefitWithCount
						asLongAsCardPasses
					}
					groupCleaning {
						chill
						essential
						relaxation
					}
					groupTransport {
						location
						foot
						publicTransport
						bike
						taxiVtc
						hitchHiking
					}
					groupTravelStyle {
						backpack
						camping
						hotel5
						safari
						greenSustainable
						guidedExcursion
						excursionGroup
						cultural
						cruise
						landscapeNatural
						beachIdleness
						roadTrip
					}
					groupTravelLevel {
						globeTrotter
						babyTraveller
						adventurer
					}
				}
				members {
					id
				}
				groupBudget {
					minimumAmount
					maximumAmount
				}
				criteria
				status
				createdAt
			}
		}
	}
`;

export const GET_GROUP = gql`
	query getGroup($groupId: String!) {
		getGroup(groupId: $groupId) {
			id
			groupName
			trip {
				id
				city {
					id
					name
					countryName
				}
				startDate
				endDate
				price
				currency
				type
				medias {
					id
					url
					name
				}
			}
			description
			membersLimit
			groupAuthor
			adminInfo {
				adminID
				adminName
				adminUsername
			}
			preferences {
				groupTravelWith {
					man
					woman
					mixed
				}
				groupAgeRange {
					age18To23
					age24To29
					age30To35
					ageOver35
				}
				groupPersonality {
					extrovert
					introvert
					ambivert
				}
				groupSociability {
					sociable
    				calm
				}
				groupInterests {
					sport
					photoVideo
					hiking
					lecture
					history
					mode
					spaAndSwimming
					food
					dance
					museum
					seriesFilm
					music
				}
				groupMood {
					party
					holySpirit
					organize
					freestyle
					morning
					night
					immortalize
					liveInMoment
				}
				groupDrinking {
					regularly
					occasionally
					noDrink
				}
				groupSmoking {
					everyday
					noSmoke
					occasionally
				}
				groupDiet {
					vegan
					hallal
					casher
					vegetarian
					prescetarian
					eatAll
				}
				groupSpending {
					asLittleAsPossible
					benefitWithCount
					asLongAsCardPasses
				}
				groupCleaning {
					chill
					essential
					relaxation
				}
				groupTransport {
					location
					foot
					publicTransport
					bike
					taxiVtc
					hitchHiking
				}
				groupTravelStyle {
					backpack
					camping
					hotel5
					safari
					greenSustainable
					guidedExcursion
					excursionGroup
					cultural
					cruise
					landscapeNatural
					beachIdleness
					roadTrip
				}
				groupTravelLevel {
					globeTrotter
					babyTraveller
					adventurer
				}
			}
			members {
				id
			}
			groupBudget {
				minimumAmount
				maximumAmount
			}
			criteria
			status
			createdAt
		}
	}
`;

export const GET_REPORT = gql`
	query getReport($reportId: String!) {
		getReport(reportId: $reportId) {
			id
			group {
				id
				groupName
			}
			reportingUser {
				id
				firstName
				lastName
				email
				media {
					id
					url
				}
			}
			reportedUser {
				id
				firstName
				lastName
				email
				media {
					id
					url
				}
			}
			type
			reportCount
			readByAdmin
			adminReply
			createdAt
		}
	}
`;

export const LIST_REPORTS = gql`
	query listReports($page: Int!, $limit: Int!, $sort: SortAndFilter) {
		listReports(page: $page, limit: $limit, sort: $sort) {
			reports {
				id
				group {
					id
					groupName
				}
				reportingUser {
					id
					firstName
					lastName
					email
					media {
						id
						url
					}
				}
				reportedUser {
					id
					firstName
					lastName
					email
					media {
						id
						url
					}
				}
				type
				reportCount
				readByAdmin
				adminReply
				createdAt
			}
			totalCount
		}
	}
`;
import { gql } from "@apollo/client";

export const CREATE_COUPON = gql`
  mutation createCoupon(
    $name: String!
    $amountOff: Int
    $percentOff: Float
    $durationInMonths: Int!
    $maxUserLimit: Int
    $products: [String!]!
  ) {
    createCoupon(
      name: {
        name: $name
        amountOff: $amountOff
        percentOff: $percentOff
        durationInMonths: $durationInMonths
        maxUserLimit: $maxUserLimit
        products: $products
      }
    ) {
      success
      message
    }
  }
`;

// export const CREATE_COUPON1 = gql`
//   mutation createCoupon($coupon: CreateCouponInput!) {
//     createCoupon(coupon: $coupon) {
//       success
//       message
//     }
//   }
// `;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($id: String!) {
    deleteCoupon(id: $id) {
      success
      message
    }
  }
`;

// export const UPDATE_COUPON = gql`
//   mutation updateCoupon($coupon: UpdateCouponInput!, $id: String!) {
//     updateCoupon(coupon: $coupon, id: $id) {
//       id
//       firstName
//       lastName
//     }
//   }
// `;

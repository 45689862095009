

export enum FilterEnum {
    cityIDFilter = "cityId",
    continentFilter = "continent",
    departureCityFilter = "departureId",
    createdAt = "createdAt",
    categoryFilter= "categoryIds",
    priceMinFilter= "priceMin",
    priceMaxFilter= "priceMax",
    departureDateFilter = "departureDates",
    monthFilter = "month",
    statusFilter= "status"
}


import React from "react";
import style from "./ProfileBNARight.module.scss";
import { QueryResult } from "@apollo/client";
import {
	getUser,
	getUserVariables,
} from "src/graphql/queries/__generated__/getUser";

interface IProfileBNAProps {
	info: QueryResult<getUser, getUserVariables>
}

const ProfileBNARight: React.FunctionComponent<IProfileBNAProps> = (props) => {
	const user = props.info.data?.user;

	const userTravelWith = () => {
		switch (user?.travelWith) {
			case 'solo':
				return 'Seul';
			case 'duo':
				return 'En couple';
			case 'group':
				return 'En groupe';
			default:
				return;
		}
	}

    return (
        <>
            <div className={style["wrapper"]}>
                <div className={style["wrapper-profile-img"]}>
                    <img src={user?.media?.url ?? "/img/default-profile-pic.png"} alt="profile" />
                </div>
                <div className={style["wrapper-info"]}>
                    <div className={style["wrapper-info-userName"]}>{`${user?.firstName} ${user?.lastName}`}</div>
					{user?.subscriptionStatus == "active" ?
						<div className={style["wrapper-info-status__brokit"]}>Bro’Kit</div>
						:
						<div className={style["wrapper-info-status__brokie"]}>Brokie</div>
					}
                </div>
            </div>
            <div className={style["personal"]}>
				<div className={style["personal__title"]}>Informations personnelles</div>
				<div className={style["personal-wrapper"]}>
					<div className={style["personal-container"]}>
						<div className={style["personal-container__title"]}>
                            <img src="/img/mail-icon.svg" alt="mail" />Adresse mail
                        </div>
						<div className={style["personal-container__value"]}>{user?.email}</div>
					</div>
					<div className={style["personal-container"]}>
						<div className={style["personal-container__title"]}>
                            <img src="/img/telephone-icon.svg" alt="telephone" />Téléphone
                        </div>
						<div className={style["personal-container__value"]}>{user?.phoneNumber}</div>
					</div>
					<div className={style["personal-container"]}>
						<div className={style["personal-container__title"]}>
                            <img src="/img/location-icon.svg" alt="location" />Adresse
                        </div>
						<div className={style["personal-container__value"]}>{user?.address}</div>
					</div>
				</div>
			</div>
            <div className={style["essential"]}>
				<div className={style["essential__title"]}>Informations de voyage</div>
				<div className={style["essential-wrapper"]}>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Ville de départ</div>
						<ul className={style["essential-container__tags"]}>
							{user?.departures?.map((departure, index) => (
								<li key={index} className={style["essential-container__tag"]}>{departure.name}</li>
							))}
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Préférence de voyage</div>
						{user?.travelWith ?
							<ul className={style["essential-container__tags"]}>
								<li className={style["essential-container__tag"]}>{userTravelWith()}</li>
							</ul>
							:
							""
						}
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Style de voyage</div>
						<ul className={style["essential-container__tags"]}>
							{user?.favoriteTripCategories?.map((category, index) => (
								<li key={index} className={style["essential-container__tag"]}>{category.name}</li>
							))}
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Contexte de voyage</div>
						<ul className={style["essential-container__tags"]}>
							{user?.favoriteTripContexts?.map((context, index) => (
								<li key={index} className={style["essential-container__tag"]}>{context.name}</li>
							))}
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Destination favori</div>
						<ul className={style["essential-container__tags"]}>
							{user?.favoriteDestinations?.map((destination, index) => (
								<li key={index} className={style["essential-container__tag"]}>{destination.name}</li>
							))}
						</ul>
					</div>
					<div className={style["essential-container"]}>
						<div className={style["essential-container__title"]}>Budget</div>
						<ul className={style["essential-container__tags"]}>
							<li className={style["essential-container__tag"]}>100-200€</li>
						</ul>
					</div>
				</div>
			</div>
        </>
    )
}

export default ProfileBNARight;

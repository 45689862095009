import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import style from "./PTMMessages.module.scss";
import Path from "../../Path/Path";
import Pagination, { LIMIT_PAGINATION } from "../../IU/Pagination/Pagination";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_REPORTS } from "src/graphql/queries/ptm";
import { UPDATE_REPORT } from "src/graphql/mutations/ptm";
import {
    listReports,
	listReportsVariables,
} from "src/graphql/queries/__generated__/listReports";
import {
    updateReport,
	updateReportVariables,
} from "src/graphql/mutations/__generated__/updateReport";

const PTMMessages = () => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const p = queryParameters.get("p");
    const [imageSrcMap, setImageSrcMap] = useState({});
    const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);
    const listReports = useQuery<listReports, listReportsVariables>(
		LIST_REPORTS,
		{ variables: { page: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION, sort: { fields: "created_at=-" } }, fetchPolicy: "network-only" }
	);
    const reports = listReports.data?.listReports.reports;
    const totalCount = listReports.data?.listReports.totalCount;
    const [updateReport] = useMutation<updateReport, updateReportVariables>(UPDATE_REPORT);

    const messageDetail = (id: string, read: boolean) => {
        if (!read) {
            const formData = {
                readByAdmin: true
            }
            updateReport({
                variables: {
                    reportId: id,
                    input: formData
                },
            });
        }

		navigate(`/ptm/ptm-messages/${id}`);
	};

    // Initialize imageSrcMap when reports data is fetched
    useEffect(() => {
        if (reports) {
            const initialImageSrcMap = reports.reduce((acc, report) => {
                acc[report.id] = '/svg/important-icon.svg';
                return acc;
            }, {});
            setImageSrcMap(initialImageSrcMap);
        }
    }, [reports]);

    const handleClick = (reportId) => {
        setImageSrcMap((prevMap) => ({
            ...prevMap,
            [reportId]: prevMap[reportId] === '/svg/important-icon.svg' 
                ? '/svg/important-fill.svg' 
                : '/svg/important-icon.svg'
        }));
    };

    const formatCreatedDate = (timestamp) => {
        const dateTime = new Date(timestamp * 1000);
    
        const day = dateTime.getDate().toString().padStart(2, '0');
        const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
        const year = dateTime.getFullYear();
        const hour = dateTime.getHours().toString().padStart(2, '0');
        const minute = dateTime.getMinutes().toString().padStart(2, '0');
    
        const isDST = (date) => {
            const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
            const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
            return date.getTimezoneOffset() < Math.max(january, july);
        };
    
        const timezone = isDST(dateTime) ? 'CEST' : 'CET';
    
        return [
            `${day}-${month}-${year}`,
            `${hour}:${minute} ${timezone}`
        ];
    };

	const reportType = (type) => {
		switch (type) {
			case 'harassment':
				return 'Harcèlement';
			case 'identity_fraud':
				return `Unsurpation d'identité`;
			case 'scams':
				return 'Arnaques';
			case 'inappropriate_content':
				return 'Partage des contenus inappropriés';
			case 'hate_speech':
				return 'Discours haineux';
			default:
				return;
		}
	}

    const messageType = (type) => {
        switch (type) {
			case 'harassment':
				return ` a été signalée pour comportement de harcèlement.`;
			case 'identity_fraud':
				return ` a été signalé pour fraude d'identité.`;
			case 'scams':
				return ` a été signalé pour arnaque.`;
			case 'inappropriate_content':
				return ` a été signalé pour avoir partagé du contenu inapproprié.`;
			case 'hate_speech':
				return ` a été signalé pour discours de haine.`;
			default:
				return '';
		}
    }

    return (
        <>
            <div className={style["upper-section"]}>
                <Path />
                <div className={style["upper-section-right"]}>Tous les messages:&nbsp;<span>{totalCount}</span></div>
            </div>
            <div className={style["container"]}>
                <table className={style["container-table"]} cellSpacing="0">
                    <thead>
                        <tr className={style["container-table-header"]}>
                            <th className={style[""]}></th>
                            <th className={style[""]}>Date</th>
                            <th className={style[""]}>Utilisateur</th>
                            <th className={style[""]}>Nom du groupe</th>
                            <th className={style[""]}>Sujet</th>
                            <th className={style[""]}>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports?.map((report) => (                        
                            <tr
                                key={report?.id}
                                onClick={() => messageDetail(report?.id, report?.readByAdmin)}
                                className={report?.readByAdmin ? style["row"] : style[""]}
                            >
                                <td>
                                    <div className={style["row-first-child"]}>
                                        <input type="checkbox" name="row" id="" onClick={(e) => e.stopPropagation()} />
                                        <img 
                                            src={imageSrcMap[report.id] || '/svg/important-icon.svg'} 
                                            alt="Icon" 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleClick(report.id)
                                            }} 
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>{formatCreatedDate(report?.createdAt)[0]}</div>
                                    <div>{formatCreatedDate(report?.createdAt)[1]}</div>
                                </td>
                                <td>
                                    <div>{report?.reportingUser?.firstName} {report?.reportingUser?.lastName}</div>
                                    <div className={style["row-email"]}>{report?.reportingUser?.email}</div>
                                </td>
                                <td>{report?.group?.groupName}</td>
                                <td>{reportType(report?.type)}</td>
                                <td>
                                    L'utilisateur{" "}
                                    <span className={style["reported-user"]}>
                                        {report?.reportedUser?.firstName} {report?.reportedUser?.lastName}
                                        <span className={style["report-count"]}> ({report?.reportCount})</span>
                                    </span>
                                    {messageType(report?.type)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
					<tr>
						<td colSpan={15}>
							<Pagination
                                page={page}
                                setPage={setPage}
                                isEmpty={!reports || reports.length === 0}
                                totalOfItems={reports?.length}
                            />
						</td>
					</tr>
				</tfoot>
                </table>
            </div>
        </>
    );
}

export default PTMMessages;
import * as React from "react";
import style from "./ProfilePTMRight.module.scss";
import { QueryResult } from "@apollo/client";
import {
	getPreferences,
	getPreferencesVariables,
} from "src/graphql/queries/__generated__/getPreferences";
import {
	getUser,
	getUserVariables,
} from "src/graphql/queries/__generated__/getUser";

interface IProfilePTMProps {
	prefs: QueryResult<getPreferences, getPreferencesVariables>,
	info: QueryResult<getUser, getUserVariables>
}

const ProfilePTMRight: React.FunctionComponent<IProfilePTMProps> = (props) => {
	const preferences = props.prefs.data?.getUserPreferences;
	const user = props.info.data?.user;

	const userPronoun = () => {
		if(preferences?.userGender?.male) {
			return 'Il';
		} else {
			return 'Elle';
		}
	}

	const userPersonality = () => {
		const personality = preferences?.userPersonality;
		if (personality?.extrovert) {
			return 'Extraverti';
		} else if (personality?.introvert) {
			return 'Introverti';
		} else if (personality?.ambivert) {
			return 'Ambiverti';
		} else {
			return null;
		}
	}

	const userSociability = () => {
		const sociability = preferences?.userSociability;
		if (sociability?.sociable) {
			return 'Sociable';
		} else if (sociability?.calm) {
			return 'Réservé';
		} else {
			return null;
		}
	}

	const userSmoking = () => {
		const smoking = preferences?.userSmoking;
		if (smoking?.everyday) {
			return 'Tous les jours';
		} else if (smoking?.noSmoke) {
			return 'Never';
		} else if(smoking?.occasionally) {
			return 'Occasionnellement';
		} else {
			return null;
		}
	}

	const userDrinking = () => {
		const drinking = preferences?.userDrinking;
		if (drinking?.regularly) {
			return 'Régulièrement';
		} else if (drinking?.occasionally) {
			return 'Occasionnellement';
		} else if(drinking?.noDrink) {
			return 'Never';
		} else {
			return null;
		}
	}

	const userCleaning = () => {
		const cleaning = preferences?.userCleaning;
		if (cleaning?.chill) {
			return 'Rohh! ça va';
		} else if (cleaning?.essential) {
			return 'Essentiel';
		} else if(cleaning?.relaxation) {
			return 'En détente';
		} else {
			return null;
		}
	}
	
	const userDiet = () => {
		const diet = preferences?.userDiet;
		if (diet?.vegan) {
			return 'Vegan';
		} else if (diet?.hallal) {
			return 'Halal';
		} else if(diet?.casher) {
			return 'Casher';
		} else if(diet?.vegetarian) {
			return 'Végétarien';
		} else if(diet?.prescetarian) {
			return 'Pescétarien';
		} else if(diet?.eatAll) {
			return 'Ici ça graille!';
		} else {
			return null;
		}
	}
	
	const userSpending = () => {
		const spending = preferences?.userSpending;
		if (spending?.asLittleAsPossible) {
			return 'Le moins possible';
		} else if (spending?.benefitWithCount) {
			return 'Profite mais compter quand même';
		} else if(spending?.asLongAsCardPasses) {
			return 'Tant que la carte passe';
		} else {
			return null;
		}
	}

	const userMood = preferences?.userMood
	const moodTags = userMood ? Object.keys(userMood).filter((key) => userMood[key] === true).map((key) => {
		switch (key) {
			case 'party':
				return 'Que la mala';
			case 'holySpirit':
				return 'Zen';
			case 'organize':
				return 'Organisé';
			case 'freestyle':
				return 'Freestyle';
			case 'morning':
				return 'Matinal';
			case 'night':
				return `Grassse mat'`;
			case 'immortalize':
				return 'Immortaliser le mmt';
			case 'liveInMoment':
				return 'Vivre le mmt présent';
			default:
				return key.charAt(0).toUpperCase() + key.slice(1);
		}
	}) : [];

	const userTravelLevel = () => {
		const travelLevel = preferences?.userTravelLevel;
		if (travelLevel?.globeTrotter) {
			return 'Globe trotteur (15+ pays)';
		} else if (travelLevel?.babyTraveller) {
			return 'Baby traveler (1-5 pays)';
		} else if(travelLevel?.adventurer) {
			return 'Baroudeur (5-15 pays)';
		} else {
			return null;
		}
	}

	return (
		<>
			<div className={style["wrapper"]}>
				<div className={style["wrapper-profile-img"]}>
					<img src={user?.media?.url ?? "/img/default-profile-pic.png"} alt="profile" />
				</div>
				<div className={style["wrapper-info"]}>
					<div className={style["wrapper-info-userName"]}>{`${user?.firstName} ${user?.lastName}`}</div>
					{preferences ?
						<div className={style["wrapper-info-age"]}>{user?.gender == "h" ? "Homme" : "Femme"}, {preferences?.userAge} ans</div>
						:
						""
					}
					{user?.subscriptionStatus == "active" ?
						<div className={style["wrapper-info-status__brokit"]}>Bro’Kit</div>
						:
						<div className={style["wrapper-info-status__brokie"]}>Brokie</div>
					}
				</div>
			</div>
			{preferences ?
				<>
					<div className={style["essential"]}>
						<div className={style["essential__title"]}>Informations essentielles</div>
						<div className={style["essential-wrapper"]}>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>Langue parlé</div>
								<ul className={style["essential-container__tags"]}>
									{preferences?.userLanguage?.map((language, index) => (
										<li key={index} className={style["essential-container__tag"]}>{language}</li>
									))}
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>L'exp. de voyage</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userTravelLevel()}</li>
								</ul>
							</div>
							{/* <div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>MBTI</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>ENFP</li>
								</ul>
							</div> */}
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>Personnalité</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userPersonality()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>Caractère</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userSociability()}</li>
								</ul>
							</div>
						</div>
					</div>
					<div className={style["essential"]}>
						<div className={style["essential__title"]}>Les habitudes de voyage</div>
						<div className={style["essential-wrapper"]}>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>{userPronoun()} fume</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userSmoking()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>{userPronoun()} bois</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userDrinking()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>{`Pour le ménage, c’est`}</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userCleaning()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>{`Pour la nourriture, c'est`}</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userDiet()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>Habitude de dépense</div>
								<ul className={style["essential-container__tags"]}>
									<li className={style["essential-container__tag"]}>{userSpending()}</li>
								</ul>
							</div>
							<div className={style["essential-container"]}>
								<div className={style["essential-container__title"]}>En vacances, {userPronoun().toLowerCase()} est plus</div>
								<ul className={style["essential-container__tags"]}>
									{moodTags.map((mood, index) => (
										<li key={index} className={style["essential-container__tag"]}>{mood}</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</>
				:
				<>
					<div className={style["no-ptm"]}>Aucun data</div>
				</>
			}
		</>
	);
};

export default ProfilePTMRight;

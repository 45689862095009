import { useNavigate, useLocation } from "react-router-dom";
import style from "./Path.module.scss";
import { MapPathToString, PathEnum, MapPathToURL } from "../../enums/path";

const Path = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split("/");
  const pathFiltered: string[] = path.filter((p) => p != null && p !== "");
  console.log(pathFiltered)

  // Determine context-specific label for :id
  const getDynamicLabel = (parentPath: string) => {
    switch (parentPath) {
      case "users":
        return "Modifier utilisateur";
      case "deals":
        return "Modifier bon plan";
      case "coupon":
        return "Modifier le coupon";
      case "ptm-groups":
        return "Modifier groupe PTM";
      case "ptm-messages":
        return "Message";
      default:
        return "Modifier";
    }
  };

  return (
    <div className={style["path"]}>
      <div className={style["path-home"]} onClick={() => navigate("/")}>
        Tableau de bord
      </div>
      {pathFiltered.map((p, i) => {
        const isLast = pathFiltered.length === i + 1;
        const url = `/${pathFiltered.slice(0, i + 1).join("/")}`;
        let displayText = MapPathToString[p] || p; // Default label for non-ID paths

        let regex = new RegExp("^[a-f0-9]{24}$")
        if (regex.test(p) === true) {
          displayText = getDynamicLabel(pathFiltered[i - 1]);
        }

        // Disable navigation for specific paths
        const isDisabled = p === PathEnum.PTM;

        return (
          <div className={style["path-details"]} key={i}>
            <img src="/svg/chevron-right-blue.svg" alt="chevron" />
            <span
              className={`${isLast ? style["path--active"] : ""} ${
                isDisabled ? style["path--disabled"] : ""
              }`}
              onClick={() => {
                if (!isDisabled) navigate(url);
              }}
            >
              {displayText}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default Path;

import style from "./UserTags.module.scss";

export interface IUserTagsProps {
	title: string;
	tags: string[];
	titleClassname?: string;
	borderRadius?: number;
}

const UserTags = ({title, tags, titleClassname, borderRadius}: IUserTagsProps) => {
	return (
		<div className={style["wrapper"]}>
			<div className={style[`${titleClassname}`] ?? style["wrapper-title"]}>{title}</div>
			<ul className={style["wrapper-tags"]}>
				{tags?.map((t) => (
					<li
						key={t}
						className={style["wrapper-tags__tag"]}
						style={{...(borderRadius && {borderRadius: `${borderRadius}px`})}}
					>
						{t}
					</li>
				))}
			</ul>
		</div>
	);
};

export default UserTags;

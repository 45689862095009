import style from "./LocationList.module.scss"
import Pagination, {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import React from "react";
import ListOptions from "../ListOptions";

const LocationList = ({locations, page, setPage}: any) => {
    return <table className={style["wrapper"]}>
        <thead>
            <tr className={style['header']}>
                <th className={style['id']}>#</th>
                <th className={style['visibility']}>Région</th>
                <th className={style['city']}>Pays</th>
                <th className={style['city']}>Ville</th>
                <th className={style['options']}></th>
            </tr>
        </thead>
        <tbody>
        {locations?.cities?.map((location)=>(
            <tr key={location.id}>
                <td className={style['id']} data-label="id">{location?.id}</td>
                <td className={style['city']} data-label="name">{location?.stateName}</td>
                <td className={style['country']} data-label="icone">{location?.countryName}</td>
                <td className={style['city']} data-label="icone">{location?.name}</td>
                <td className={style['']}>
                    <ListOptions data={location} page={page} isCity={true}/>
                </td>
            </tr>
        ))}
        </tbody>
        <tfoot>
        <tr>
            <td colSpan={17}>
                <Pagination
                    page={page}
                    setPage={setPage}
                    isEmpty={locations?.length === 0 || !locations || locations?.length < LIMIT_PAGINATION}
                    totalOfItems={locations?.totalCount}
                />
            </td>
        </tr>
        </tfoot>
    </table>
}

export default LocationList
import style from './ListCategories.module.scss'
import React from "react";
import Pagination from "../IU/Pagination/Pagination";
import ListOptions from "../ListOptions";

const ListCategories = ({setCurrentCategory, tripCategories, page, setPage}: any) => {


    return (
        <div className={style['container']}>

            <table className={style['container-table']} cellSpacing="0">
                <thead>
                <tr className={style['container-table-header']}>
                    <th className={style['container-table-options']}>#</th>
                    <th className={style['container-table-header-visibility']}>Nom</th>
                    <th className={style['container-table-header-city']}>Icone</th>
                    <th className={style['container-table-options']}></th>
                </tr>
                </thead>
                <tbody>
                {tripCategories?.map((category) => (
                    <tr key={category.id}>
                        <td className={style['container-table-header-id']} data-label="id">{category.id}</td>
                        <td className={style['container-table-header-city']} data-label="name">{category.name}</td>
                        <td className={style['container-table-header-country']} data-label="icone"><img
                            src={category.icon?.url} className={style['icon']} alt={"icone"}/></td>
                        <td className={style['container-table-options']}>
                            <ListOptions data={category} page={page} setData={setCurrentCategory}/>
                        </td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={4}>
                        <Pagination
                            page={page}
                            setPage={setPage}
                            isEmpty={!tripCategories || tripCategories?.length === 0}
                            totalOfItems={tripCategories?.length}
                        />
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    )

}

export default ListCategories
import style from './Gallery.module.scss'
import {useQuery} from "@apollo/client";
import React, { useState} from "react";
import {LIST_MEDIAS} from "../../../../graphql/queries/media";
import {
    ListMedias,
    ListMedias_listMedias,
    ListMediasVariables
} from "../../../../graphql/queries/__generated__/ListMedias";
import GalleryForm from "./GalleryForm";
import Loader from "../../../IU/Loader/Loader";
import Pagination, {LIMIT_PAGINATION} from "../../../IU/Pagination/Pagination";

interface IGalleryProps   {
    setImages: any,
    setOpenModal: any,
    images: any,
    isMultiple?: boolean
}
const isMediaSelected = (mediaSelected, id) =>{

    return mediaSelected?.findIndex( m=> m?.id === id) !== -1;
}

const Gallery = ({setImages, setOpenModal, images, isMultiple = true}: IGalleryProps) => {
    const [page, setPage] = useState( 0)

    const [mediaSelected, setMediaSelected] = useState<ListMedias_listMedias[]>(images)
    const {loading, error, data} = useQuery<ListMedias, ListMediasVariables>(LIST_MEDIAS, { variables: {id: (page*20), limit: LIMIT_PAGINATION, sort:{filters : "directory=trips",fields:"created_at=+" }}})
    if (loading) return <div className={style['wrapper']}><Loader/></div>;
    if (error) return <div>`Error! ${error.message}`</div>;

    const clickHandler = (media: ListMedias_listMedias) => {
        if (mediaSelected){
            if (isMediaSelected(mediaSelected, media.id) ){
                setMediaSelected(mediaSelected?.filter(m => m.id !== media.id))
            }
            if (isMultiple  && mediaSelected){
                if (!isMediaSelected(mediaSelected, media.id)) {
                    setMediaSelected(prevState => [...prevState, media])
                }
            }
            else {
                if (!isMediaSelected(mediaSelected, media.id)){
                    setMediaSelected([media])
                }
            }
        }
        else{
            setMediaSelected([media])
        }

    }

    const handleScroll = (e) =>{

            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) {
            }
    }

    return(
        <div className={style['wrapper']}>
            <div className={style['content']}>


                <div onScroll={handleScroll} className={` ${style['content-gallery']} ${mediaSelected.length === 0 ? style['content-gallery__active'] : style['content-gallery__disable']} `}>
                    {data?.listMedias?.map((media) => (
                        <div key={media.id}  id={media.id.toString()} className={`${style['content-gallery-image']} ${(isMediaSelected(mediaSelected, media.id) )  && style['content-gallery-image-selected'] } `}  onClick={()=>{clickHandler(media)}}>
                            <img src={media.url} alt={media.name} loading="lazy"/>
                        </div>
                    ))
                    }


                </div>

            {(mediaSelected.length !== 0 ) &&
            <div className={style['content-form__active']}>
                <GalleryForm medias={mediaSelected} setImages={setImages} setOpenModal={setOpenModal} isMultiple={isMultiple}/>
            </div>
        }
            </div>
            <Pagination
                page={page}
                setPage={setPage}
                isEmpty={data?.listMedias?.length === 0 || !data?.listMedias}
                totalOfItems={data?.listMedias?.length}
                backgroundColor={"none"}
            />
        </div>
    )
};
export default Gallery
import {useLocation, useNavigate, useParams} from "react-router-dom"

import {useMutation, useQuery} from "@apollo/client";
import {GetTrip, GetTripVariables} from "../../graphql/queries/__generated__/GetTrip";
import {GET_TRIP, LIST_TRIP} from "../../graphql/queries/trip";
import {updateTrip, updateTripVariables} from "../../graphql/mutations/__generated__/updateTrip";
import {Fragment, useState} from "react";
import DealFormAffiliate, {changeToTimestampUtc, encodeText, MySwal, Toast, TRAVEL_TYPE} from "./DealFormAffiliate";
import Loader from "../IU/Loader/Loader";
import {LIMIT_PAGINATION} from "../IU/Pagination/Pagination";
import Swal from "sweetalert2";
import {DELETE_TRIP, UPDATE_TRIP} from "../../graphql/mutations/trip";
import {CreateTripInput, UpdateTripInput} from "../../../__generated__/globalTypes";
import {diffDays} from "./newDeal";
import Path from "../Path/Path";
import style from "./newDeal.module.scss";

type errors = {
    [key: string]: boolean;
};

const Deal = ()  => {
    const navigate = useNavigate()
    const location = useLocation();
    const params = useParams();
    const [errors, setErrors] = useState<any>({});
    const [deleteTrip] = useMutation(DELETE_TRIP);
    const [updateTrip] = useMutation<updateTrip, updateTripVariables>(UPDATE_TRIP);
    const {loading, error, data} = useQuery<GetTrip, GetTripVariables>(GET_TRIP, { variables: { id: params?.id! }, fetchPolicy: "network-only"})
    if (loading) return <div><Loader/></div>;
    if (error) return <div>`Error! ${error.message}`</div>;
    const isADuffelDeal = (data?.trip.provider === 'duffel')
    const getErrorsFromFormData = (formData) =>{
        const emptyFields: errors = {};
        Object.keys(formData).forEach((field: string) => {
            if (formData[field] === "" || formData[field] == undefined || formData[field] == null) {
                emptyFields[field] = true;
            }
            if (field === 'contexts' || field === 'categories' || field === 'medias'){
                if(formData[field]?.length === 0){
                    emptyFields[field] = true;
                }
            }
        });
        if (formData.startDate === formData.endDate){
            emptyFields['searchPeriod'] = true
        }
        if (
            Object.keys(emptyFields).length > 0
        ) {
            setErrors({...emptyFields});
            return null;
        }

        setErrors({});
    }
    const getFormDataForOther = (e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace): any => {
        const nbOfDays = diffDays(startDate, endDate)
        const formData: CreateTripInput | UpdateTripInput  = {
            affiliationLink: e.target['affiliation'].value,
            longDescription: encodeText(description),
            arrivalId: arrivalCity.id,
            returnId: departureCity.id,
            categories: categoriesSelected?.map(c => c.id),
            cityId: arrivalCity.id,
            continent: continentSelected?.id,
            countryId: arrivalCountry.id,
            currency: "EUR",
            departureId: departureCity.id,
            departureStation: departurePlace.iataCode,
            endDate: changeToTimestampUtc(endDate),
            medias: images?.map(image => (image?.id)),
            numberOfPassengers: parseInt(e.target['nbTravelers'].value),
            numberOfDays: nbOfDays,
            numberOfNights: nbOfDays - 1,
            price: parseFloat(e.target['price'].value),
            shortDescription: encodeText(anecdote),
            startDate: changeToTimestampUtc(startDate),
            type: travelTypeSelected?.id,
            travelWith: travelWithSelected?.id,
            visibility: e.target['visibility'].value,
            lowCarbonTravel: (travelTypeSelected?.id === TRAVEL_TYPE[3].id || travelTypeSelected?.id === TRAVEL_TYPE[4].id) ,
            contexts: contextSelected?.map(c => c.id),
        };
        getErrorsFromFormData(formData)
        if (Object.keys(errors).length > 0){
            return
        }

        return formData;
    }
    const onSubmitFormHandler = async(e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace) => {
        e.preventDefault();
        const formData =  getFormDataForOther(e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace);
        if(!formData){
            return
        }
        try {
            const id= data?.trip?.id
            if(id){
                await updateTrip({variables: {trip: formData, id: id}})
                await Toast.fire({
                    icon: 'success',
                    title: 'Deal à jour'
                })
                navigate(`/deals?p=${location?.state}`)
            }

        } catch(e)  {
            await MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                // @ts-ignore
                text: 'Erreur lors de la création \n ' + e.message,
            })
        }
    }

    const deleteTripHandler = (id: string) => {
        Swal.fire({

            title: 'Voulez-vous vraiment supprimé ce deal?',
            text: "Il sera impossible d'annuler cette action!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD4646',
            cancelButtonColor: '#2D2C6C',
            confirmButtonText: 'Supprimer !',
            cancelButtonText: 'Annuler !',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                try {
                    deleteTrip({
                        variables: {
                            id: id
                        }, refetchQueries: [{
                            query: LIST_TRIP, variables:{id: 0, limit: LIMIT_PAGINATION}}]
                    })
                    Swal.fire(
                        'Supprimé!',
                        'Le deal a bien été supprimé.',
                        'success'
                    )
                    navigate('/deals')
                }
                catch (e:any) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong: \n '+ e.message,
                    })
                }

            }
        })

    }

    return (
        <Fragment>
            <Path />
            <div className={style['wrapper']}>

                    <Fragment>
                        <div className={style['wrapper-header']}>
                            <span className={style['wrapper-header-title']}> Mise à jour Bon plan</span>
                        </div>
                        <div className={style['wrapper-line']}></div>
                    </Fragment>
            <DealFormAffiliate formErrors={errors} deal={data?.trip} handleSubmit={onSubmitFormHandler} deleteTripHandler={deleteTripHandler}/>
            </div>
        </Fragment>


    );
}

export default Deal;
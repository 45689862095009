import style from "./Select.module.scss";
import { useEffect, useRef, useState } from "react";
import ArrowShort from "../ArrowShort/ArrowShort";

interface ISelectProps {
  value: any;
  onChange: any;
  data: any;
  withoutIcon?: boolean;
  placeholder?: string;
  isMultiple?: boolean;
  defaultValue?: any;
  isDisable?: boolean;
  isWithBorder?: boolean;
  isCrossIcon?: boolean;
}

export default function Select({
  value,
  data,
  withoutIcon = false,
  onChange,
  isMultiple = false,
  isDisable = false,
  isWithBorder = true,
  isCrossIcon = true,
}: ISelectProps) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [highlight, setHighlight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const clearHandler = () => {
    isMultiple ? onChange([]) : onChange(undefined);
  };

  const selectOption = (option) => {
    if (isMultiple) {
      if (value.includes(option)) {
        onChange(value.filter((o) => o !== option));
      } else {
        onChange([...value, option]);
      }
    } else {
      if (option !== value) onChange(option);
    }
  };
  const isOptionSelected = (option) => {
    if (value) {
      return isMultiple
        ? value.some((v) => option === v.id)
        : option === value.id;
    }
    return;
  };

  useEffect(() => {
    if (showDropDown) setHighlight(0);
  }, [showDropDown]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return;
      switch (e.code) {
        case "Enter":
        case "Space":
          setShowDropDown((prev) => !prev);
          if (showDropDown) selectOption(data[highlight]);
          break;
        case "ArrowUp":
        case "ArrowDown": {
          if (!showDropDown) {
            setShowDropDown(true);
            break;
          }

          const newValue = highlight + (e.code === "ArrowDown" ? 1 : -1);
          if (newValue >= 0 && newValue < data.length) {
            setHighlight(newValue);
          }
          break;
        }
        case "Escape":
          setShowDropDown(false);
          break;
      }
    };
    containerRef.current?.addEventListener("keydown", handler);

    return () => {
      containerRef.current?.removeEventListener("keydown", handler);
    };
  }, [showDropDown, highlight, data]);

  return (
    <div
      ref={containerRef}
      onBlur={() => setShowDropDown(false)}
      onClick={() => setShowDropDown((prev) => !prev)}
      tabIndex={0}
      className={`${style["container"]} ${isDisable && style["disable"]} ${
        isWithBorder && style["border"]
      } `}
    >
      <span className={style["value"]}>
        {isMultiple && Array.isArray(value)
          ? value.map((v) => (
              <button
                key={v.id}
                onClick={(e) => {
                  e.stopPropagation();
                  selectOption(v);
                }}
                className={style["option-badge"]}
              >
                {v?.name}
                <span className={style["remove-btn"]}>
                  <img src={"/svg/close.svg"} alt={"close"} />
                </span>
              </button>
            ))
          : value?.name}
      </span>
      {isCrossIcon && (
        <button
          className={`${isWithBorder ? style["clear-btn"] : style["none"]}`}
          type={"button"}
          onClick={(e) => {
            e.stopPropagation();
            clearHandler();
          }}
        >
          &times;
        </button>
      )}
      {!withoutIcon && <ArrowShort direction={showDropDown ? "up" : "down"} />}

      <ul className={`${style["options"]} ${showDropDown && style["show"]}`}>
        {data?.map((d, i) => (
          <li
            key={d.id}
            onClick={(e) => {
              e.stopPropagation();
              selectOption(d);
              setShowDropDown(false);
            }}
            onMouseEnter={() => setHighlight(i)}
            className={`${style["option"]} ${
              isOptionSelected(d.id) && style["selected"]
            } ${i === highlight && style["highlighted"]}`}
          >
            {d.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

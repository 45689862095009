import ListOptions from "src/components/ListOptions";
import style from "./ListUserGroups.module.scss";
import {useEffect, useRef, useState} from "react";
import Button from "src/components/IU/Button/Button";
import GroupDetails from "./GroupDetails";
import {
	getGroupByAdmin_getGroupByAdmin_createdGroups,
	getGroupByAdmin_getGroupByAdmin_joinedGroups,
} from "src/graphql/queries/__generated__/getGroupByAdmin";

export interface IListUserGroupsProps {
	showCreatedGroups?: boolean,
	createdGroups?: Array<getGroupByAdmin_getGroupByAdmin_createdGroups>,
	joinedGroups?: Array<getGroupByAdmin_getGroupByAdmin_joinedGroups>,
}

const ListUserGroups = ({showCreatedGroups, createdGroups, joinedGroups}: IListUserGroupsProps) => {
	const options: any = useRef();
	const [groups, setGroups] = useState<any>([]);
	const queryParameters = new URLSearchParams(window.location.search);
	const p = queryParameters.get("p");
	const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);

	useEffect(() => {
		if (p === null) {
			setPage(0);
		}
	}, [p]);

	const formatFrenchStartDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

	const formatFrenchEndDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long", year: "numeric" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

	const formatCreatedDate = (timestamp: number): string => {
		const date = new Date(timestamp * 1000);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	}

	const tripType = (type: string) => {
		switch (type) {
			case 'all':
				return 'Vol+Hôtel';
			case 'flight':
				return 'Vol A/R';
			case 'hotel':
				return 'Hôtel';
			case 'bus':
				return 'Bus A/R';
			case 'train':
				return 'Train A/R';
			default:
				return;
		}
	}

	useEffect(() => {
		if (showCreatedGroups) {
			setGroups(createdGroups?.map((group) => ({
				id: group.id,
				tripDetails: {
					city: group.trip?.city?.name,
					country: group.trip?.city?.countryName,
					tripType: tripType(group.trip?.type ?? ""),
					startDate: formatFrenchStartDate(group.trip?.startDate ?? 0),
					endDate: formatFrenchEndDate(group.trip?.endDate ?? 0),
					price: group.trip?.price,
					thumbnail: group.trip?.medias[0]?.url ?? "/img/default-card-bg.jpg",
				},
				groupName: group.groupName,
				maxMembers: group.membersLimit,
				author: group.adminInfo.adminName,
				status: group.status,
				date: formatCreatedDate(group.createdAt ?? 0),
				gender: group.preferences?.groupTravelWith,
				members: group.members?.length,
				description: group.description,
				budget: {
					minimumAmount: group.groupBudget.minimumAmount,
					maximumAmount: group.groupBudget.maximumAmount,
				},
				criteria: group.criteria,
			})));
		} else {
			setGroups(joinedGroups?.map((group) => ({
				id: group.id,
				tripDetails: {
					city: group.trip?.city?.name,
					country: group.trip?.city?.countryName,
					tripType: tripType(group.trip?.type ?? ""),
					startDate: formatFrenchStartDate(group.trip?.startDate ?? 0),
					endDate: formatFrenchEndDate(group.trip?.endDate ?? 0),
					price: group.trip?.price,
					thumbnail: group.trip?.medias[0]?.url ?? "/img/default-card-bg.jpg",
				},
				groupName: group.groupName,
				maxMembers: group.membersLimit,
				author: group.adminInfo.adminName,
				status: group.status,
				date: formatCreatedDate(group.createdAt ?? 0),
				gender: group.preferences?.groupTravelWith,
				members: group.members?.length,
				description: group.description,
				budget: {
					minimumAmount: group.groupBudget.minimumAmount,
					maximumAmount: group.groupBudget.maximumAmount,
				},
				criteria: group.criteria,
			})));
		}
	}, [showCreatedGroups, createdGroups, joinedGroups]);

	const onOpenOptions = () => {};

	return (
		<div className={style["container"]}>
			<table className={style["container-table"]} cellSpacing="0">
				<thead>
					<tr className={style["container-table-header"]}>
						<th className={style[""]}>Destination</th>
						<th className={style[""]}>Nom de groupe</th>
						<th className={style[""]}>Nbre de pers.</th>
						<th className={style[""]}>Créateur</th>
						<th className={style[""]}>Statut</th>
						<th className={style[""]}>Date</th>
						<th className={style[""]}></th>
					</tr>
				</thead>
				<tbody>
					{groups?.map((group, index) => (
						<GroupDetails group={group} key={index} />
					))}
				</tbody>
				{/* <tfoot>
            <tr>
                <td colSpan={15}>
                
                    <Pagination page={page} setPage={setPage} isEmpty={false} totalOfItems={2} />
                </td>
            </tr>
        </tfoot> */}
			</table>
		</div>
	);
};

export default ListUserGroups;

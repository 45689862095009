import {useEffect, useState} from "react";
import style from "./PTMGroups.module.scss";
import { useQuery } from "@apollo/client";
import {useNavigate} from "react-router-dom";
import Pagination, {LIMIT_PAGINATION} from "../../IU/Pagination/Pagination";
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import PTMGroupDetails from "./PTMGroupDetails";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LIST_GROUPS } from "src/graphql/queries/ptm";
import {
    listGroups,
	listGroupsVariables,
} from "src/graphql/queries/__generated__/listGroups";

const PTMGroups = () => {
    const [isOpen, setIsOpen] = useState<any>(false);
    const [arrowUp, setArrowUp] = useState<any>("dropdown-arrow-down");
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const queryParameters = new URLSearchParams(window.location.search);
    const p = queryParameters.get("p");
    const [page, setPage] = useState(p ? parseInt(p) - 1 : 0);
    const navigate = useNavigate();
    const newGroupHandler = () => {
		navigate("/ptm/ptm-groups/new-group");
	};
    const [groups, setGroups] = useState<any>([]);
    const listGroups = useQuery<listGroups, listGroupsVariables>(
		LIST_GROUPS,
		{ variables: { page: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION, isDashboard: true }, fetchPolicy: "network-only" }
	);
    const allGroups = listGroups.data?.listGroups.groups;
    const allCount = listGroups.data?.listGroups.totalCount;
    const deletedGroups = allGroups?.filter(group => group.deletedAt !== 0);
    const validCount = listGroups.data?.listGroups.validCount;
    const pastCount = listGroups.data?.listGroups.pastCount;


    const options = [
        { label: "Validés", value: "valid", count: validCount },
        { label: "Passés", value: "past", count: pastCount },
        { label: "Supprimé", value: "deleted", count: deletedGroups?.length },
    ];

    const formatFrenchStartDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

	const formatFrenchEndDate = (timestamp: number): string => {
		return new Date(timestamp * 1000)
        	.toLocaleDateString('fr-FR', { day: "numeric", month: "long", year: "numeric" })
        	.replace(/\b(\w)/g, (match) => match.toUpperCase());
	}

	const formatCreatedDate = (timestamp: number): string => {
		const date = new Date(timestamp * 1000);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();
		return `${day}-${month}-${year}`;
	}

	const tripType = (type: string) => {
		switch (type) {
			case 'all':
				return 'Vol+Hôtel';
			case 'flight':
				return 'Vol A/R';
			case 'hotel':
				return 'Hôtel';
			case 'bus':
				return 'Bus A/R';
			case 'train':
				return 'Train A/R';
			default:
				return;
		}
	}

	useEffect(() => {
        setGroups(allGroups?.map(group => formatGroupData(group)));
    }, [allGroups]);

    const formatGroupData = (group) => ({
        id: group.id,
        tripDetails: {
            city: group.trip?.city?.name,
            country: group.trip?.city?.countryName,
            tripType: tripType(group.trip?.type ?? ""),
            startDate: formatFrenchStartDate(group.trip?.startDate ?? 0),
            endDate: formatFrenchEndDate(group.trip?.endDate ?? 0),
            price: group.trip?.price,
            thumbnail: group.trip?.medias[0]?.url ?? "/img/default-card-bg.jpg",
        },
        groupName: group.groupName,
        maxMembers: group.membersLimit,
        author: group.adminInfo.adminName,
        status: group.status,
        date: formatCreatedDate(group.createdAt ?? 0),
        gender: group.preferences?.groupTravelWith,
        members: group.members?.length,
        description: group.description,
        budget: { minimumAmount: group.groupBudget.minimumAmount, maximumAmount: group.groupBudget.maximumAmount },
        criteria: group.criteria,
        deletedAt: group.deletedAt
    });

    const handleCheckboxChange = (optionValue) => {
        setSelectedOptions((prev) =>
            prev.includes(optionValue)
                ? prev.filter((value) => value !== optionValue)
                : [...prev, optionValue]
        );
    };

    const setGroupFilter = () => {
        const filteredGroups = allGroups?.filter((group) => {
            return (
                selectedOptions.length == 0 ||
                (selectedOptions.includes("valid") && group.status === "valid" && group.deletedAt == 0) ||
                (selectedOptions.includes("past") && group.status === "past" && group.deletedAt == 0) ||
                (selectedOptions.includes("deleted") && group.deletedAt != 0)
            );
        });
        setGroups(filteredGroups?.map((group) => formatGroupData(group)));
    };

    useEffect(() => {
        setGroupFilter();
    }, [selectedOptions]);

    // const dragEnded = (param) => {
    //     const { source, destination } = param;
    //     let arr = [...groups];
    //     //extracting the source item from the list
    //     const item = arr.splice(source.index, 1)[0];
    //     //inserting it at the destination index.
    //     arr.splice(destination.index, 0, item);
    //     setGroups(arr);
    // };

    return (
        <>
            <div className={style["upper-section"]}>
                <Path />
                <div className={style["upper-section-right"]}>
                    <div className={style["upper-section-right-addBtn"]}>
                        <Button
                            text={"Ajouter un groupe"}
                            color={"#FFF"}
                            width={155}
                            height={33}
                            borderRadius={7}
                            backgroundColor={"#343E92"}
                            prefixIcon={{
                                url: "/svg/add.svg",
                                alt: "add",
                            }}
                            onClick={newGroupHandler}
                        />
                    </div>
                    <div>
                        <button
                            className={style["upper-section-right-drpBtn"]}
                            onClick={() => {
                                setIsOpen(!isOpen);
                                arrowUp == "dropdown-arrow-down" ? setArrowUp("dropdown-arrow-up") : setArrowUp("dropdown-arrow-down");
                            }}
                        >
                            Tous les groupes ({allCount})
                            <span className={style[`${arrowUp}`]}>
                                <img src="/svg/dropdown-fill.svg" alt="dropdown-arrow" />
                            </span>
                        </button>
                        {isOpen && (
                            <div className={style["dropdown-list"]}>
                                {options.map((option, index) => (
                                    <label key={index} className={style["dropdown-list-option"]}>
                                        <input
                                            type="checkbox"
                                            checked={selectedOptions.includes(option.value)}
                                            onChange={() => handleCheckboxChange(option.value)}
                                        />
                                        {option.label} ({option.count})
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={style["container"]}>
                <table className={style["container-table"]} cellSpacing="0">
                    <thead>
                        <tr className={style["container-table-header"]}>
                            <th className={style[""]}></th>
                            <th className={style[""]}>Destination</th>
                            <th className={style[""]}>Nom du groupe</th>
                            <th className={style[""]}>Nbr de pers.</th>
                            <th className={style[""]}>Créateur</th>
                            <th className={style[""]}>Participants</th>
                            <th className={style[""]}>Statut</th>
                            <th className={style[""]}>Date de création</th>
                            <th className={style[""]}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <DragDropContext onDragEnd={dragEnded}>
                            <Droppable droppableId="comments-wrapper">
                            {groups?.map((group, index) => (
                                <Draggable draggableId={`comment`} index={index} >
                                    <PTMGroupDetails group={group} key={index} />
                                </Draggable>
                            ))}
                            </Droppable>
                        </DragDropContext> */}
                        {groups?.map((group, index) => (
                            <PTMGroupDetails group={group} key={index} />
                        ))}
                    </tbody>
                    <tfoot>
					<tr>
						<td colSpan={15}>
							<Pagination
                                page={page}
                                setPage={setPage}
                                isEmpty={!groups || groups.length === 0}
                                totalOfItems={groups?.length}
                            />
						</td>
					</tr>
				</tfoot>
                </table>
            </div>
        </>
    )
}

export default PTMGroups
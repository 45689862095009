import {useState} from "react";
import style from "./ListUserGroups.module.scss";
import Button from "src/components/IU/Button/Button";
import ListOptions from "src/components/ListOptions";
import UserTags from "../Tags/UserTags";

export interface IGroupDetailsProps {
	group?: any;
}

const GroupDetails = ({group}: IGroupDetailsProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const gender = group.gender ? Object.keys(group.gender).filter((key) => group.gender[key] === true).map((key) => {
		switch (key) {
			case 'man':
				return 'Homme';
			case 'woman':
				return 'Femme';
			case 'mixed':
				return 'Mixte';
			default:
				return key.charAt(0).toUpperCase() + key.slice(1);
		}
	}) : [];

	return (
		<>
			<tr key={group?.id} className={isOpen ? style["border-zero"] : ""}>
				<td>
					<div className={style["container-table-trip"]}>
						<div className={style["container-table-trip-img"]}>
							<img src={group?.tripDetails?.thumbnail} alt="group-thumbnail" />
						</div>
						<div className={style["container-table-trip-details"]}>
							<div className={style["container-table-trip-location"]}>
								{`
        ${group?.tripDetails?.city}, ${group?.tripDetails?.country}`}
							</div>
							<div className={style["container-table-trip-type"]}>
								{`
        ${group?.tripDetails?.tripType}`}
							</div>
							<div className={style["container-table-trip-type"]}>
								{`
        ${group?.tripDetails?.startDate} - ${group?.tripDetails?.endDate}`}
							</div>
							<div className={style["container-table-trip-price"]}>
								{`
        ${group?.tripDetails?.price} €`}
							</div>
						</div>
					</div>
				</td>
				<td className={style[""]}>
					<div className={style["container-table-type"]}>{group?.groupName}</div>
				</td>
				<td className={style["container-table-value"]}>{`${group?.maxMembers} pers.`}</td>
				<td className={style[""]}>
					<div className={style["container-table-type"]}>{group?.author}</div>
				</td>
				<td className={style["container-table-status"]}>
					<div
						className={group?.status == "valid" ? style["container-table-status-active"] : style["container-table-status-disable"]}
					>
						{group?.status == "valid" ? "Validé" : "Passé"}
					</div>
				</td>
				<td className={style[""]}>
					{
						// formatCreatedAtDate(
						group?.date
						// )
					}
				</td>
				<td>
					<div className={style["container-table-actions"]}>
						<span
							className={`${style["container-table-actions-icon"]} ${
								isOpen ? style["container-table-actions-icon__active"] : ""
							}`}
							onClick={() => {
								setIsOpen(!isOpen);
							}}
						>
							<img src="/svg/dropdown-icon.svg" alt="dropdown-icon" />
						</span>

						<ListOptions data={group} page={0} />
					</div>
				</td>
			</tr>
			{isOpen && (
				<tr className={style["append-border"]}>
					<td colSpan={7}>
						<div className={`${style["container-group"]} ${isOpen ? style["container-group_active"] : ""} `}>
							<div className={style["container-group-left"]}>
								<div className={style["container-group-left-details"]}>
									<div className={style["container-group-left-details-item"]}>
										<div className={style["container-group-title"]}>Genre souhaité</div>
										<div className={style["container-group-text"]}>{gender}</div>
									</div>
									<div className={style["container-group-left-details-item"]}>
										<div className={style["container-group-title"]}>Niveau de compatibilité</div>
										<div className={style["container-group-text"]}>80.3%</div>
									</div>
									<div className={style["container-group-left-details-item"]}>
										<div className={style["container-group-title"]}>Participants</div>
										<div className={style["container-group-text__light"]}>
											{`${group.members}/${group.maxMembers}`}
											{group.members === group.maxMembers ?
												<span className={style["container-group-text__light__green"]}>{" "}Complet</span>
												:
												<span className={style["container-group-text__light__red"]}>{" "}Incomplet</span>
											}
											{/* <span className={style["container-group-text__light__green"]}>{group.members === group.maxMembers ? "Complet" : "Incomplet"}</span> */}
										</div>
									</div>
									<div className={style["container-group-left-details-item"]}>
										<div className={style["container-group-title"]}>Budget</div>
										<div className={style["container-group-text__yellow"]}>{`${group.budget.minimumAmount}-${group.budget.maximumAmount}€`}</div>
									</div>
								</div>
								<div className={style["container-group-left-desc"]}>
									<div className={style["container-group-left-desc-item"]}>
										<div className={style["container-group-title"]}>Description du groupe</div>
										<div className={style["container-group-text__light"]}>
											{group.description}
										</div>
									</div>
									<UserTags
										titleClassname={"container-group-title"}
										title="Critère du groupe"
										tags={group.criteria}
										borderRadius={16}
									/>
								</div>
							</div>
							{/* <div className={style["container-group-right"]}>
								<div className={style["container-group-right__link"]}>
									<span className={style["container-group-right__link-icon"]} style={{}}>
										<img alt="Empty Link" src={"/svg/link-icon.svg"} height={14} width={14} />
									</span>
									<input className={style["container-group-right__link-text"]} value={"https://chat.whatsapp.com..."} />
								</div>
								<div className={style["container-group-right__btns"]}>
									<Button
										text={"Valider"}
										type={"button"}
										color={"#fff"}
										backgroundColor={"#AEAEAE"}
										width={115}
										height={35}
										borderRadius={5}
										prefixIcon={{
											url: "/svg/tick.svg",
											alt: "icône tick",
											style: {},
										}}
									/>
									<Button
										text={"Refuser"}
										type={"button"}
										color={"#737373"}
										backgroundColor={"#fff"}
										width={115}
										height={35}
										borderRadius={5}
										borderColor={"#737373"}
									/>
								</div>
							</div> */}
						</div>
					</td>
				</tr>
			)}
		</>
	);
};

export default GroupDetails;
